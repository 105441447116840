@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap&subset=latin-ext");
@import url("https://fonts.googleapis.com/css2?family=Spartan:wght@500;700&display=swap");

body {
  min-height: 100vh;
  height: 100%;
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.no-scroll {
    overflow: hidden;
    max-height: 100vh;
  }

  ul,
  ol,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none !important;
  }

  &::-webkit-scrollbar {
    width: 20px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ddd;

    &:hover {
      background-color: rgba(@color1, 0.8);
    }
  }
}

.alert-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.2);
  z-index: 555;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;

  .alert-service {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    transform: translateX(8px);
    padding: 50px 0;

    .service-inner {
      padding: 50px;
      background: #fff;
      width: 100%;
      box-shadow: 0px 0px 32px 1px rgba(0, 0, 0, 0.15);
      text-align: center;
      border-top: 5px solid #fff;
      position: relative;
      transform: scale(0.8);
      transition: all 0.5s;

      .inner-dismiss {
        top: 10px;
        right: 10px;
        position: absolute;
        border: 0;
        background: none;
        padding: 0;
        cursor: pointer;
        display: flex;
        padding: 5px;
        outline: none;

        img {
          height: 15px;
        }
      }

      &.success {
        border-top: 5px solid #78b042;
      }

      &.warning {
        border-top: 5px solid #d51a27;
      }

      &.info {
        border-top: 5px solid #1a88d5;
      }
    }
  }

  &.active {
    opacity: 1;
    visibility: visible;

    .alert-service {
      .service-inner {
        transform: none;
      }
    }
  }
}

.fixed-button {
  position: fixed;
  left: -400px;
  height: 200px;
  bottom: 25vh;
  z-index: 444;
  display: flex;
  align-items: center;
  transition: 500ms;
  animation: shake-animation 4.72s ease infinite;
  transform-origin: 50% 50%;

  &:hover,
  &:focus,
  &:active {
    left: 0;
  }

  .button-wrapper {
    display: flex;
    align-items: center;

    .service-button {
      background-color: @color1;
      cursor: pointer;
      height: 60px;
      width: 220px;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        color: #fff;
        font-weight: 600;
        font-size: 15px;
        letter-spacing: 0.5px;
      }
    }

    .service-box {
      width: 400px;
      height: 200px;
      background-color: #f5f5f5;
      box-shadow: 0 0 10.4px 5.6px rgba(47, 47, 47, 0.07);
      padding: 30px 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      h3 {
        margin-bottom: 10px;
        font-size: 22px;
      }

      p {
        font-size: 15px;
        line-height: 26px;
      }

      a {
        color: @color1;
        font-weight: 600;
        display: block;
        margin-top: 10px;
        font-size: 17px;
      }
    }
  }
}

@keyframes shake-animation {
  0% {
    transform: translate(0, 0);
  }
  1.78571% {
    transform: translate(5px, 0);
  }
  3.57143% {
    transform: translate(0, 0);
  }
  5.35714% {
    transform: translate(5px, 0);
  }
  7.14286% {
    transform: translate(0, 0);
  }
  8.92857% {
    transform: translate(5px, 0);
  }
  10.71429% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

nav.section-header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 555;
  width: 100%;
  padding: 0 50px;

  .header-flexbox {
    padding: 25px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .flexbox-logo {
      img {
        width: 220px;
      }
    }

    .flexbox-nav-wrapper {
      .nav-button-close {
        position: absolute;
        display: none;
        width: 100%;
        top: 20px;
        left: 0;

        .container {
          display: flex;
          justify-content: flex-end;

          &:before,
          &:after {
            content: none;
          }
        }

        button {
          outline: none;
          border: none;
          background: none;
          padding: 15px;
          display: flex;
          justify-content: center;
          align-items: center;

          .button-bar {
            display: block;
            position: absolute;
            width: 30px;
            height: 2px;
            background: #222;

            &:nth-child(1) {
              transform: rotate(45deg);
            }

            &:nth-child(2) {
              transform: rotate(-45deg);
            }
          }
        }
      }

      .flexbox-nav-inner {
        .flexbox-nav {
          display: flex;
          justify-content: space-between;
          align-items: center;

          li {
            padding: 0 20px;

            a {
              color: #000;
              font-weight: 500;
              letter-spacing: 1.5px;
              text-transform: uppercase;
              padding-bottom: 5px;
              transition: all 250ms;
              font-size: 14px;
            }

            &:hover {
              a {
                color: @color1;
              }
            }

            &.active {
              &.desktop {
                .dropdown-toggle {
                  color: @color1;
                }
              }

              a {
                color: @color1;
              }
            }

            &.featured {
              padding: 10px 15px;
              background-color: rgba(@color1, 0.85);
              transition: all 250ms;

              a {
                font-weight: 700;
                color: #fff;
              }

              &:hover {
                background-color: @color1;
              }
            }

            &.social-media {
              padding: 0 0 0 20px;

              img {
                width: 40px;
              }
            }

            &.desktop {
              .dropdown-toggle {
                outline: none;
                border: none;
                font-size: 15px;
                text-transform: uppercase;
                letter-spacing: 1.5px;
                color: #000;
                font-weight: 500;
                background: transparent;
                cursor: pointer;
                transition: color 350ms;

                &:hover {
                  color: @color1;
                }

                &::after {
                  display: none;
                }
              }

              .slideIn {
                animation-name: slideIn;
              }

              .animate {
                animation-duration: 400ms;
                animation-fill-mode: both;
              }

              @keyframes slideIn {
                0% {
                  transform: translateY(0);
                  opacity: 0;
                }

                100% {
                  transform: translateY(25px);
                  opacity: 1;
                }

                0% {
                  transform: translateY(0);
                  opacity: 0;
                }
              }

              .dropdown-menu {
                position: relative;
                border-radius: 0;
                border: none;
                margin-top: 15px;
                padding: 0;

                &.show {
                  left: -95px !important;
                }

                &:before {
                  content: "";
                  display: block;
                  position: absolute;
                  left: 125px;
                  top: -8px;
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 0 8px 8px 8px;
                  border-color: transparent transparent rgba(0, 0, 0, 0.75)
                    transparent;
                }

                .menu-wrapper {
                  background: rgba(0, 0, 0, 0.75);
                  padding: 0 30px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding: 20px;
                }

                .dropdown-item {
                  position: relative;
                  padding: 5px 10px;
                  font-size: 12.5px;
                  font-weight: 500;
                  transition: all 0.3s;
                  transform: none !important;
                  width: auto;
                  color: #fff;
                  text-align: center;

                  &:not(:last-of-type) {
                    &:after {
                      position: absolute;
                      top: 7px;
                      right: 0;
                      content: "";
                      display: block;
                      width: 1px;
                      height: 15px;
                      background-color: #fff;
                    }
                  }

                  &:hover {
                    color: @color1;
                    background: transparent;
                  }
                }
              }
            }

            &.mobile {
              display: none;
              flex-direction: column;
              align-items: center;

              .collapse-list {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 10px;

                li {
                  padding: 3px 0;

                  a {
                    font-size: 14px;
                    font-weight: 400;
                    color: #000;

                    &:active {
                      background-color: transparent;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .nav-button {
      padding: 10px;
      border: none;
      background: none;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      outline: none;
      display: none;

      .button-bar {
        height: 2px;
        width: 24px;
        background: @color1;
        border-radius: 10px;
        transition: all 0.4s;

        &:nth-child(2) {
          margin-top: 4px;
          width: 27px;
        }

        &:nth-child(3) {
          margin-top: 4px;
          width: 30px;
        }
      }

      &:hover,
      &:focus {
        .button-bar {
          width: 30px !important;
        }
      }
    }
  }
}

// variables

@color1: #e95216;
@color2: #3a5f89;

// mixins

.responsive-shape (@width, @padding-top) {
  position: relative;
  width: @width;
  padding-top: @padding-top;
}

.responsive-shape-content () {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.centered-element () {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn {
  background-color: transparent;
  border-radius: 0;
  padding: 10px 28px;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1.5px;
  transition: all 350ms;
  border: 1px solid transparent;

  &.btn-default {
    background-color: #2c2b29;
    color: #fff;

    &:hover {
      background-color: transparent;
      border: 1px solid #2c2b29;
      color: @color1;
    }
  }

  &.btn-arrow {
    font-size: 16px;
    padding: 0;
    font-weight: 500;
    text-transform: none;
    color: #000;
    display: flex;
    align-items: center;

    &.light {
      color: #fff;

      span {
        color: #fff;
      }
    }

    span {
      font-size: 40px;
      font-weight: 600;
      color: #000;
      transition: color 350ms;
      display: inline-block;
      transform: translateX(8px);
      transition: all 350ms;
    }

    &:hover {
      color: @color1;

      span {
        color: @color1;
        transform: translateX(12px);
      }
    }
  }
}

.offer-element {
  transform: translateY(50px);
  display: flex;
  justify-content: space-between;

  .offer-item {
    .responsive-shape(30%, 24%);
    overflow: hidden;
    color: #fff;
    box-shadow: 0 19px 20px rgba(0, 0, 0, 0.1), 0 15px 12px rgba(0, 0, 0, 0.1);
    transition: all 250ms;

    .offer-text {
      .responsive-shape-content;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 20px;
    }

    h2 {
      margin-bottom: 20px;
      font-size: 28px;
    }

    &:hover {
      .overlay {
        opacity: 1;
      }
    }

    .overlay {
      position: absolute;
      z-index: 11;
      opacity: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      transition: all 350ms;

      span {
        font-size: 20px;
        font-weight: 600;
        color: #fff;
      }
    }

    &:first-of-type {
      background-color: rgba(@color2, 0.9);

      .overlay {
        background-color: @color2;
      }
    }

    &:nth-of-type(2) {
      background-color: rgba(#2c2b29, 0.9);

      .overlay {
        background-color: #2c2b29;
      }
    }

    &:nth-of-type(3) {
      background-color: rgba(@color1, 0.9);

      .overlay {
        background-color: @color1;
      }
    }
  }
}

.section-heading {
  width: 100%;

  h2,
  h3 {
    margin-bottom: 20px;
    line-height: 42px;

    span {
      position: relative;
      display: inline-block;
      font-size: 27px;

      &:before {
        position: absolute;
        z-index: -1;
        bottom: 5px;
        left: 2px;
        content: "";
        width: 103%;
        height: 12px;
        background-color: @color1;
      }
    }
  }
}

.main-section-heading {
  padding: 180px 0 80px 0;
  width: 70%;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Spartan", sans-serif;

  img {
    width: 80px;
    margin-bottom: 30px;
  }

  h1 {
    font-size: 46px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  p {
    line-height: 28px;
    font-size: 22px;
    letter-spacing: 0.5px;
    text-align: center;
  }
}

.section-description {
  padding-top: 80px;
  text-align: center;

  h2 {
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 20px;

    img {
      width: 150px;
      transform: translate(15px, -5px);
    }

    strong {
      color: @color1;
    }
  }

  p {
    padding: 0 7%;
    line-height: 28px;

    &:not(:first-of-type) {
      margin-top: 10px;
    }
  }
}

.section-cooperation {
  padding: 80px 0 60px 0;

  h2 {
    text-align: center;
  }

  .owl-carousel-cooperation {
    margin-top: 40px;

    .owl-stage-outer,
    .owl-stage,
    .owl-item {
      height: 150px;
    }

    .item {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      img {
        width: 50%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

section.section-welcome {
  .welcome-background {
    background: url(../img/backgrounds/bg.jpg) 0 15% no-repeat;
    background-size: cover;
  }

  .welcome-wrapper {
    padding: 100px 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .row-wrapper {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      padding: 1% 2.5%;

      .welcome-element {
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #000;
        background-color: #f4f4f4;
        transition: background-color 350ms;

        &:hover {
          background-color: #ebebeb;

          span {
            color: @color1;
          }
        }

        .badge {
          position: absolute;
          top: 20px;
          right: 0;
          min-width: 200px;
          height: 30px;
          padding: 5px 10px;
          background-color: @color1;
          color: #fff;
          text-transform: uppercase;
          font-size: 14px;
          letter-spacing: 0.5px;
          font-weight: 600;
          border-radius: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        header {
          width: 45%;
        }

        .element-image {
          position: relative;
          width: 50%;

          img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        h2 {
          position: relative;
          z-index: 1;
          margin-bottom: 10px;
          font-weight: 600;
        }

        span {
          display: inline-block;
          margin-top: 20px;
          text-transform: uppercase;
          font-weight: 700;
          letter-spacing: 0.5px;
          transition: color 350ms;
        }
      }

      &.top {
        .welcome-element {
          text-align: right;
          padding-right: 30px;

          header {
            margin-top: 30px;
          }

          h2 {
            font-size: 28px;
            line-height: 34px;
          }

          span {
            font-size: 14px;
          }

          .element-image {
            padding-top: 90%;
          }

          &:first-of-type {
            width: 32%;
          }

          &.img-background {
            background: url(../img/backgrounds//bg.jpg) 0 28% no-repeat;
            background-size: cover;
            width: 66%;
          }
        }
      }

      &.bottom {
        .welcome-element {
          width: 32%;
          padding-left: 30px;

          h2 {
            font-size: 20px;
            line-height: 26px;
          }

          p {
            font-size: 14px;
          }

          .element-image {
            padding-top: 50%;
          }

          span {
            font-size: 12px;
          }

          .element-image {
            img {
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
}

section.section-about {
  position: relative;
  overflow: hidden;

  &.p-100 {
    padding: 100px 0;
  }

  .section-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.reverse {
      flex-direction: row-reverse;
    }

    .section-text {
      width: 45%;

      h3 {
        font-weight: 700;
        font-size: 40px;
        line-height: 42px;
        margin-bottom: 30px;

        span {
          position: relative;
          display: inline-block;
          font-size: 27px;

          &:before {
            position: absolute;
            z-index: -1;
            bottom: 5px;
            left: 2px;
            content: "";
            width: 103%;
            height: 12px;
            background-color: @color1;
          }
        }
      }

      p {
        line-height: 28px;

        &:not(:first-of-type) {
          margin-top: 10px;
        }
      }

      .btn-default {
        margin-top: 30px;
      }
    }

    .section-img {
      position: relative;
      width: 50%;
      padding-top: 40%;
      background-color: #f4f4f4;

      img {
        position: absolute;
        top: 0;
        left: 0;
        padding: 15px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .img-absolute {
    position: absolute;
    z-index: -1;
    transform: scale(-1, 1);
    right: -100px;
    bottom: -3%;
    opacity: 0.05;
    width: 400px;
  }
}

section.section-offer {
  position: relative;
  margin-top: 40px;

  .mobile {
    display: none;
    text-align: center;
    margin-bottom: 10px;
    font-weight: 700;
  }

  .bg-wrapper {
    position: absolute;
    top: -50px;
    z-index: -1;
    background-color: #f4f4f4;
    width: 53%;
    height: 112%;
  }

  .owl-carousel-offer {
    height: 50%;
    width: 100%;

    .owl-stage-outer,
    .owl-stage,
    .owl-item {
      height: 100%;
    }

    .owl-dots {
      display: none;
    }

    .owl-nav {
      margin-top: 20px;
      display: flex;
      justify-content: center;

      .owl-prev,
      .owl-next {
        outline: none;

        span {
          font-size: 40px;
          line-height: 30px;
          font-weight: 600;
          color: #000;
        }

        &:first-of-type {
          span {
            margin-right: 10px;
          }
        }

        &:nth-of-type(2) {
          span {
            margin-left: 10px;
          }
        }
      }
    }

    .item {
      display: flex;
      align-items: center;

      .item-img {
        position: relative;
        width: 45%;
        padding-top: 25%;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          padding: 20px 20px 20px 0;
        }
      }

      .item-text {
        width: 55%;
        padding: 0 250px 0 75px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        h3 {
          font-size: 50px;
          font-weight: 700;
          margin-bottom: 30px;
        }

        p {
          line-height: 28px;

          &:not(:first-of-type) {
            margin-top: 10px;
          }
        }

        .btn-default {
          margin-top: 30px;
        }
      }
    }
  }
}

section.section-opinion {
  position: relative;
  margin: 100px 0;

  .section-parallax {
    background-image: url("../img/@temp/parallax.jpg");
    height: 500px;
    opacity: 0.5;
    width: 100%;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .owl-carousel-opinion {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;

    .owl-stage-outer,
    .owl-stage,
    .owl-item {
      height: 100%;
    }

    .owl-nav {
      position: relative;
      bottom: 150px;
      display: flex;
      justify-content: center;

      .owl-prev,
      .owl-next {
        outline: none;

        span {
          font-size: 40px;
          font-weight: 600;
          color: #000;
          margin: 0 10px;
        }
      }
    }

    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      width: 40%;
      margin: 0 auto;
      height: 100%;

      h4 {
        margin-bottom: 20px;
        font-weight: 600;
        font-size: 28px;
      }

      p {
        line-height: 28px;
      }

      span {
        display: block;
        font-weight: 600;
        font-size: 20px;
        margin-top: 20px;
      }
    }
  }
}

section.section-cta {
  margin: 100px 0 40px 0;
  padding: 40px 0 100px 0;

  .section-wrapper {
    position: relative;
    background-color: #2c2b29;

    .img-absolute {
      position: absolute;
      height: 100%;
      width: 50%;
      opacity: 0.1;
      top: 0;
      left: 0;
    }

    .cta-content {
      position: relative;
      display: flex;
      align-items: center;

      .cta-text {
        background-color: #f4f4f4;
        color: #000;
        width: 47%;
        padding: 40px;
        transform: translateY(40px);
        box-shadow: 0 19px 20px rgba(0, 0, 0, 0.1),
          0 15px 12px rgba(0, 0, 0, 0.1);

        h2 {
          font-size: 28px;
          margin-bottom: 20px;
          font-weight: 600;
          line-height: 42px;

          strong {
            color: @color1;
            font-size: 34px;
          }
        }

        .btn-arrow {
          margin-top: 10px;
        }
      }

      .cta-img {
        position: absolute;
        width: 45%;
        right: -70px;

        img {
          width: 85%;
          height: 85%;
          object-fit: cover;
        }
      }
    }
  }
}

.section-process {
  padding-top: 80px;
  display: flex;
  flex-direction: column;

  .process-flexbox {
    padding-top: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      width: 30px;
    }

    .process-stage {
      width: calc(85% / 4);
      height: 100%;
      text-align: center;
      cursor: pointer;

      button {
        border: none;
        outline: none;
        background-color: transparent;
        font-size: 18px;

        .icon {
          width: 45px;
          margin: 0 auto;
          transition: all 350ms;
        }

        .step {
          font-size: 13.5px;
          font-weight: 500;
          display: block;
          margin: 15px 0 3px 0;
        }
      }

      &:hover {
        .icon {
          transform: translateY(-8px);
        }

        .text {
          display: block;
        }
      }

      .modal-content {
        padding-bottom: 20px;

        .modal-header {
          align-items: center;

          .close:hover {
            span {
              color: @color2;
            }
          }
        }

        .modal-body {
          text-align: left;
          padding: 20px;

          p {
            line-height: 26px;
            font-size: 15px;

            &:not(:first-of-type) {
              margin-top: 10px;
            }
          }

          ul,
          ol {
            list-style: decimal;
            margin: 10px 0 10px 30px;

            li {
              padding: 5px 0;
              font-size: 15px;
            }
          }
        }
      }
    }
  }

  .process-description {
    padding-top: 20px;
    text-align: center;
  }
}

.process-cta {
  position: relative;
  overflow: hidden;
  background-color: #f5f5f5;
  margin-top: 80px;
  padding: 70px 0;

  .img-absolute {
    position: absolute;
    top: -20px;
    left: -20px;
    opacity: 0.3;
  }

  .cta-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    h3 {
      font-weight: 700;
      font-size: 32px;
    }

    .btn-default {
      margin-top: 40px;
    }

    &.cta-center {
      align-items: center;
    }
  }
}

.section-background {
  background: url(../img/backgrounds/bg3.jpg) 0 65% no-repeat;
  background-size: cover;
}

.section-gallery {
  padding-top: 60px;

  .gallery-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    a {
      .responsive-shape (23%, 23%);
      margin-top: 2%;
      box-shadow: 5px 0px 10px 0px rgba(0, 0, 0, 0.15);
      transition: transform 350ms;

      img {
        .responsive-shape-content;
        max-width: 100%;
        object-fit: cover;
        object-position: 20% 10%;
        transition: all 350ms;
      }

      &:hover {
        transform: translateY(-8px);
      }
    }

    .box-hidden {
      width: 23%;
      opacity: 0;
      visibility: hidden;
    }
  }
}

section.o-nas-section-content {
  padding-bottom: 40px;

  h2 {
    font-size: 40px;
    font-weight: 700;
  }

  .section-wrapper {
    padding-top: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.reverse {
      flex-direction: row-reverse;
    }

    .section-text {
      width: 50%;

      p {
        line-height: 28px;

        &:not(:first-of-type) {
          margin-top: 10px;
        }
      }

      ul {
        list-style: none;
        padding: 10px 0 0 20px;

        li {
          position: relative;
          font-size: 15px;
          padding: 5px 0 5px 15px;

          &:before {
            position: absolute;
            content: "";
            top: 10px;
            left: -10px;
            width: 12px;
            height: 12px;
            background-color: @color1;
          }
        }
      }
    }

    .section-heading {
      width: 45%;
      margin: 0;

      h2 {
        margin: 0;
      }
    }
  }

  .section-timeline {
    position: relative;
    margin-top: 150px;

    &:before {
      position: absolute;
      content: "";
      top: 0;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      width: 3px;
      background-color: @color1;
    }

    .timeline-wrapper {
      width: calc(100% - 80px);
      margin: auto;
      position: relative;
      left: -5px;

      .timeline {
        width: 50%;
        padding: 35px;
        text-align: right;
        margin: -30px 0 0 auto;

        &:nth-of-type(odd) {
          margin: -30px auto 0 0;
        }

        .date {
          font-size: 32px;
          margin-bottom: 12px;
          position: relative;

          span {
            font-size: 24px;
          }

          &.active {
            font-weight: 700;

            &:before {
              background-color: @color1;
              transform: scale(1.1);
            }
          }

          &:before {
            content: "";
            position: absolute;
            width: 20px;
            height: 20px;
            border: 3px solid @color1;
            background-color: #fff;
            border-radius: 100%;
            top: 50%;
            transform: translateY(-50%);
            right: -58px;
            z-index: 1000;
            transition: all 350ms;
          }

          &.big:before {
            width: 38px;
            height: 38px;
          }
        }

        .description {
          p {
            line-height: 28px;

            strong {
              font-size: 17px;
            }
          }
        }

        &:nth-child(even) {
          text-align: left;

          .date {
            &:before {
              left: -40px;
            }

            &.big:before {
              left: -50px;
            }
          }
        }
      }
    }
  }

  .section-cooperation {
    padding: 80px 0 0 0;
  }
}

.section-features {
  background-color: #f4f4f4;
  margin-top: 80px;
  padding: 80px 0;
  text-align: center;

  .features-wrapper {
    padding-top: 50px;
    display: flex;
    justify-content: space-between;

    .feature {
      width: 24%;
      display: flex;
      flex-direction: column;
      align-items: center;

      h3 {
        font-size: 16px;
        line-height: 24px;
      }

      img {
        width: 38px;
        margin-bottom: 15px;
        transition: transform 350ms;
      }

      &:hover {
        img {
          transform: translateY(-8px);
        }
      }
    }
  }
}

.section-item-list {
  padding-top: 80px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  &.leasing {
    .leasing-wrapper {
      display: flex;
      width: 100%;

      .navigation {
        margin-top: 15px;
        width: 20%;
        border-right: 1px solid @color1;

        p {
          font-size: 22px;
          font-weight: 600;
          text-transform: uppercase;
          margin: 0 0 10px 0;
        }

        ul {
          flex-direction: column;
          border: none;

          li {
            padding: 10px 0;
            transition: all 300ms;
            transform: translateZ(0);

            a {
              color: #222;
              padding: 5px 0;
              transition: all 300ms;

              &.active {
                color: #000;
                font-weight: 600;
              }
            }

            &:hover {
              transform: translatex(3px);

              a {
                color: @color1;
              }
            }
          }
        }
      }

      .leasing-content {
        width: 80%;
      }

      .leasing-description {
        padding-left: 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        h2,
        h3 {
          text-transform: uppercase;
          letter-spacing: 1px;
          color: #222;
          margin: 20px 0 5px 0;
          font-size: 18px;
        }

        ul {
          flex-direction: column;
          border: none;
          padding: 10px 0 0 20px;

          li {
            padding: 5px 0;
            transition: all 300ms;

            a {
              color: #222;
              padding: 5px 0;
              transition: all 300ms;

              &.active {
                color: @color1;
              }
            }

            &:hover {
              transform: translatex(3px);

              a {
                color: @color1;
              }
            }
          }
        }

        p {
          font-size: 15px;
          line-height: 28px;
          margin-top: 10px;
        }
      }
    }
  }

  .items-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;

    &.items-lg {
      .item {
        .responsive-shape (31.5%, 22%);
        margin: 10px;
      }
    }

    .item {
      background-color: #f4f4f4;
      color: #000;
      .responsive-shape (23%, 20%);
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.03), 0 6px 6px rgba(0, 0, 0, 0.2);
      margin: 7px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      &:nth-of-type(even) {
        background: #ddd;
      }

      &.last-tile {
        width: 30%;

        h3 {
          font-weight: 700;
          font-weight: 28px;
        }
      }

      .item-text {
        .centered-element;
        width: 95%;
        height: 95%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px;

        h3 {
          font-size: 22px;
          font-weight: 500;
        }
      }
    }
  }
}

section.offer-section-content {
  padding-bottom: 80px;

  .section-heading {
    h2,
    h3 {
      font-size: 40px;
      font-weight: 700;
    }
  }

  .section-table {
    padding-top: 60px;

    table {
      padding-top: 20px;
      border-collapse: collapse;
      width: 100%;
    }

    th,
    td {
      text-align: center;
      padding: 10px 5px;
      border-bottom: 1px solid @color1;
      font-size: 12px;
    }

    td {
      min-width: 130px;
    }

    .btn-default {
      padding: 10px 12px;
      font-size: 11px;
    }

    .section-heading {
      text-align: center;
      margin-bottom: 30px;
    }

    .modal-content {
      padding-bottom: 20px;

      .modal-header {
        align-items: center;

        .close:hover {
          span {
            color: @color2;
          }
        }
      }

      .modal-body {
        padding: 20px;
        display: flex;
        justify-content: center;

        .btn-default {
          display: block;
          margin: 10px 0;
        }

        p {
          font-size: 17px;
        }
      }
    }
  }

  .section-download {
    position: relative;
    overflow: hidden;
    background-color: #f5f5f5;
    margin-top: 80px;
    padding: 70px 0;

    .img-absolute {
      position: absolute;
      top: -20px;
      left: -20px;
      opacity: 0.3;
    }

    .download-wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      h3 {
        font-weight: 700;
        font-size: 32px;
      }

      .btn-default {
        margin-top: 40px;
      }
    }
  }

  .section-products {
    padding-top: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.reverse {
      flex-direction: row-reverse;
    }

    .owl-carousel-products {
      display: flex;
      align-items: center;
      width: 40%;

      .owl-stage {
        height: 400px;
      }

      .owl-item {
        position: relative;
        height: 100%;
      }

      .owl-nav {
        position: absolute;
        bottom: 0;
        width: 80px;
        right: 10px;
        display: flex;
        justify-content: center;
        background-color: #f4f4f4;

        .owl-prev,
        .owl-next {
          outline: none;

          span {
            font-size: 40px;
            font-weight: 600;
            color: #000;
          }

          &:first-of-type {
            span {
              margin-right: 10px;
            }
          }

          &:nth-of-type(2) {
            span {
              margin-left: 10px;
            }
          }
        }
      }

      .owl-products {
        position: relative;
        width: 100%;
        height: 100%;
        background-color: #f4f4f4;

        display: flex;
        align-items: center;
        justify-content: center;

        img {
          .centered-element;
          height: 90%;
          width: 90%;
          object-fit: contain;
          background-color: #fff;
        }
      }
    }

    .products-image {
      .responsive-shape (40%, 40%);
      background-color: #f4f4f4;

      img {
        .centered-element;
        height: 90%;
        width: 90%;
        object-fit: cover;
        object-position: top center;
      }
    }

    .products-text {
      width: 55%;

      nav {
        a {
          color: #000;
          transition: color 350ms;

          &:hover {
            color: @color1;
          }
        }
      }

      ul {
        list-style: none;
        padding: 10px 0 0 12px;
        text-align: left;

        li {
          position: relative;
          font-size: 14px;
          padding: 5px 0 5px 15px;

          &:before {
            position: absolute;
            content: "";
            top: 10px;
            left: -10px;
            width: 12px;
            height: 12px;
            background-color: @color1;
          }
        }
      }

      p {
        line-height: 28px;

        &:not(:first-of-type) {
          margin-top: 10px;
        }
      }
    }
  }

  .products-cta {
    position: relative;
    background-color: #2c2b29;
    padding: 40px 0;
    margin-top: 70px;

    .img-absolute {
      position: absolute;
      height: 100%;
      width: 50%;
      opacity: 0.1;
      top: 0;
      left: 0;
    }

    .cta-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;

      .cta-heading {
        width: 52%;
        text-align: right;
        margin-right: 150px;

        h3 {
          color: @color1;
          font-size: 46px;
          font-weight: 800;
        }
      }

      .text {
        width: 48%;

        h2 {
          font-size: 36px;
          font-weight: 700;
          line-height: 46px;
          margin-bottom: 20px;

          strong {
            color: @color1;
          }
        }

        p {
          margin-top: 10px;
        }

        .btn-arrow {
          margin-top: 30px;
        }
      }
    }
  }

  .section-distributors {
    padding-top: 80px;

    h2 {
      font-size: 42px;
      font-weight: 700;
      margin-bottom: 20px;
    }

    .distributors-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .image {
      img {
        width: 100%;
      }
    }

    .distributors-inner {
      width: 33%;
      margin-top: 40px;

      h3 {
        font-size: 22px;
        margin-bottom: 30px;
      }

      h4 {
        font-size: 16px;
        font-weight: 700;
        margin-top: 15px;
      }

      img {
        margin-top: 40px;
        width: 80%;
      }

      ul {
        list-style: none;
        padding: 10px 0 0 10px;

        li {
          position: relative;
          font-size: 16px;
          padding: 5px 0 5px 15px;

          &:before {
            position: absolute;
            content: "";
            top: 12px;
            left: -8px;
            width: 10px;
            height: 10px;
            background-color: @color1;
          }

          span {
            font-weight: 700;
          }
        }
      }
    }
  }

  .section-cooperation {
    margin-top: 70px;
    background-color: #f4f4f4;
  }
}

section.realizacje-section-content,
section.realizacja-section-content {
  padding-bottom: 80px;

  .section-realizations {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .realization {
      margin-top: 50px;
      width: 48%;
      display: flex;

      .image {
        .responsive-shape (50%, 50%);
        transition: all 350ms;
        background-color: #f5f5f5;
        padding: 10px;
        min-height: 220px;

        img {
          .responsive-shape-content;
          object-fit: cover;
          padding: 8px;
        }
      }

      .description {
        width: 50%;
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        h3 {
          font-size: 18px;
          margin-bottom: 15px;
        }

        p {
          font-size: 15px;
          line-height: 26px;
        }

        .btn-default {
          margin-top: 20px;
        }
      }

      &:hover {
        .image {
          opacity: 0.7;
        }
      }
    }
  }

  .btn-default {
    margin-top: 40px;
  }
}

section.static-page-section-content {
  padding-bottom: 80px;

  .section-description {
    text-align: left;

    p {
      padding: 0;
    }

    ul,
    ol {
      list-style: decimal;
      padding: 10px 0 10px 30px;
      text-align: left;

      li {
        font-size: 15px;
        padding: 8px 0;
      }
    }
  }
}

section.faq-section-content {
  padding-bottom: 80px;

  .accordion {
    padding-top: 70px;
    position: relative;

    .accordion-item {
      margin-bottom: 25px;

      .item-header {
        position: relative;
        padding: 0;
        padding-left: 40px;
        background: none;
        border: none;

        .service-point {
          position: absolute;
          top: 0;
          left: -10px;
          margin-right: 30px;
          min-width: 30px;
          height: 30px;
          background: @color1;
        }

        button {
          padding: 0;
          background: none;
          display: flex;
          align-items: center;
          width: 100%;
          border-radius: 0;
          text-align: left;
          text-decoration: none;
          font-size: 22px;
          font-weight: 700;
          color: @color1;
          outline: none;
          cursor: pointer;
          border: none;
          transition: all 0.3s;

          strong {
            font-weight: 700;
            color: @color1;
          }

          &.collapsed {
            color: #313131;
            font-weight: 300;
          }
        }
      }

      .item-description {
        padding: 20px 100px 0 40px;

        p,
        li {
          font-size: 16px;
          line-height: 28px;
          color: #212121;

          strong {
            font-weight: 700;
            color: @color1;
          }
        }

        p:not(:first-of-type) {
          margin-top: 10px;
        }

        ul {
          padding-left: 17px;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

section.inwestycje-section-content {
  padding-bottom: 80px;

  .section-wrapper {
    padding-top: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .section-text {
      width: 45%;

      h2 {
        font-weight: 700;
        font-size: 40px;
        line-height: 42px;
        margin-bottom: 40px;

        span {
          position: relative;
          display: inline-block;
          font-size: 27px;

          &:before {
            position: absolute;
            z-index: -1;
            bottom: 5px;
            left: 2px;
            content: "";
            width: 103%;
            height: 12px;
            background-color: @color1;
          }
        }
      }

      p {
        line-height: 28px;

        &:not(:first-of-type) {
          margin-top: 10px;
        }
      }

      ul {
        padding: 10px 0 0 18px;

        li {
          padding: 5px 0;
        }
      }
    }

    .section-img {
      position: relative;
      .responsive-shape (50%, 50%);
      background-color: #f4f4f4;

      img {
        .responsive-shape-content;
        padding: 15px;
        object-fit: cover;
      }
    }
  }

  .section-cta {
    position: relative;
    overflow: hidden;
    background-color: #f5f5f5;
    margin-top: 80px;
    padding: 60px 0;

    .img-absolute {
      position: absolute;
      top: -20px;
      left: 0;
      opacity: 0.3;
    }

    .cta-wrapper {
      text-align: right;
      position: relative;

      h3 {
        font-weight: 700;
        font-size: 32px;
        line-height: 42px;
      }

      .btn-default {
        margin-top: 40px;
      }
    }
  }

  .section-offer-list {
    .section-heading {
      h2 {
        font-size: 40px;
        font-weight: 700;
      }
    }

    .product {
      width: 23.5%;
      height: 220px;
      margin: 8px;
    }
  }
}

section.polecamy-section-content {
  padding-bottom: 70px;
  background-image: url(../img/backgrounds/bg-fixed.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-attachment: fixed;

  .section-gallery {
    padding-top: 100px;
  }

  .section-features {
    h2 {
      font-size: 40px;
      font-weight: 700;
    }
  }

  .section-wrapper {
    padding-top: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.reverse {
      flex-direction: row-reverse;

      .section-text {
        padding-right: 0;
      }

      .section-img {
        justify-content: flex-start;

        img {
          transform: rotate(-10deg);
        }
      }
    }

    .section-text,
    .section-heading {
      width: 55%;
      padding-right: 30px;
      margin: 0;

      h2 {
        font-weight: 700;
        font-size: 40px;
        line-height: 42px;
        margin-bottom: 40px;

        span {
          position: relative;
          z-index: 1;
          display: inline-block;
          font-size: 27px;

          &:before {
            position: absolute;
            z-index: -1;
            bottom: 5px;
            left: 2px;
            content: "";
            width: 103%;
            height: 12px;
            background-color: @color1;
          }
        }
      }

      p {
        line-height: 28px;

        &:not(:first-of-type) {
          margin-top: 10px;
        }
      }

      .btn-default {
        margin-top: 30px;
      }

      ul {
        list-style: none;
        padding: 0 0 10px 10px;

        li {
          position: relative;
          font-size: 17px;
          padding: 10px 0 10px 15px;

          &:before {
            position: absolute;
            content: "";
            top: 16px;
            left: -10px;
            width: 12px;
            height: 12px;
            background-color: @color1;
          }
        }
      }
    }

    .section-heading {
      width: 40%;
    }

    .section-img {
      position: relative;
      width: 40%;
      padding-top: 40%;
      display: flex;
      justify-content: flex-end;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 90%;
        height: 90%;
        object-fit: contain;
        transform: rotate(10deg);
      }
    }
  }
}

section.kontakt-section-content {
  padding-bottom: 70px;

  .section-wrapper {
    padding-top: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .section-heading {
      width: 30%;

      h3 {
        margin: 20px 0 0 0;
        font-size: 22px;
      }

      span {
        margin-top: 10px;
        display: block;
      }

      address {
        margin: 15px 0 20px 0;
      }

      a {
        color: #000;
        display: block;
        margin: 5px 0;
        transition: color 350ms;

        &:hover {
          color: @color2;
        }
      }

      .social-media {
        margin-top: 25px;
        font-size: 15px;
        color: #000;
        font-weight: 500;

        img {
          width: 40px;
          margin-right: 10px;
        }
      }

      img {
        width: 200px;
      }
    }

    .section-map {
      position: relative;
      width: 60%;
      padding-top: 40%;
      background-color: #f4f4f4;

      iframe {
        .centered-element;
        width: 97%;
        height: 97%;
      }
    }
  }

  .section-team {
    padding-top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .person {
      position: relative;
      width: 23%;
      padding-top: 18%;
      margin-top: 10px;
      background-color: #f4f4f4;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.03), 0 6px 6px rgba(0, 0, 0, 0.2);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &:nth-of-type(even) {
        background: #ddd;
      }

      .person-details {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        text-align: center;
        padding: 20px 10px;

        a {
          display: block;
          color: #000;
          font-size: 15px;
          transition: color 350ms;

          &:hover {
            color: @color2;
          }
        }

        .name {
          font-size: 18px;
          font-weight: 500;
        }

        p {
          font-size: 14px;
        }

        h3 {
          font-size: 18px;
          font-weight: 500;
        }
      }
    }

    .box-hidden {
      visibility: hidden;
      width: 24%;
    }
  }

  .heading {
    padding-top: 40px;
  }
}

section.section-footer {
  background-color: #f4f4f4;

  footer {
    .footer-flexbox {
      padding-top: 60px;
      display: flex;
      justify-content: space-between;

      .flexbox-service {
        &:first-of-type {
          p {
            font-size: 14px;
            margin: 10px 0;
            font-weight: 500;
          }

          address {
            font-size: 14.5px;
            margin: 25px 0 10px;
          }

          .contact-link {
            display: block;
            color: #000;
            font-size: 14.5px;
            font-weight: 500;
            transition: color 350ms;

            &:hover {
              color: @color2;
            }
          }
        }

        &:nth-of-type(2),
        &:nth-of-type(3) {
          margin-top: 20px;
        }

        .service-logo {
          img {
            height: 70px;
          }
        }

        h3 {
          font-size: 22px;
          margin-bottom: 15px;
        }

        .social-media {
          display: block;
          margin-top: 25px;

          img {
            height: 35px;
          }

          span {
            color: #000;
            font-size: 14.5px;
            margin-left: 10px;
            font-weight: 500;
          }
        }

        address {
          margin: 30px 0 20px 0;
          font-weight: 500;
        }

        ul {
          li {
            padding: 5px 0;

            a {
              font-size: 14.5px;
              color: #000;
              transition: color 350ms;

              &:hover {
                color: @color2;
              }
            }
          }
        }
      }
    }

    .alert {
      position: relative;
      background: none;
      border: none;
      border-radius: 0;
      padding: 0;
      width: 80%;
      margin: 40px auto;

      p {
        font-size: 13px;
        line-height: 22px;
        color: #000;
        font-weight: 300;
        text-align: center;
      }

      .alert-links {
        margin-top: 10px;
        text-align: center;

        a {
          color: @color1;
          cursor: pointer;
          transition: all 0.3s;
          margin-right: 10px;
          font-size: 12.5px;
          font-weight: 500;

          &:hover {
            color: @color2;
          }
        }
      }
    }

    .footer-credits-wrapper {
      margin-top: 10px;
      padding: 25px 0;
      background-color: #fff;

      .footer-credits {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: relative;

        p {
          font-size: 13px;
          color: #444;
          font-weight: 300;

          a {
            color: @color1;
            font-weight: 600;
          }
        }
      }
    }
  }
}
