@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLDz8Z1JlEA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiEyp8kv8JHgFVrJJnedw.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLGT9Z1JlEA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLEj6Z1JlEA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLCz7Z1JlEA.ttf) format('truetype');
}
@font-face {
  font-family: 'Spartan';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/spartan/v3/l7gAbjR61M69yt8Z8w6FZf9WoBxdBrG3uW6O.ttf) format('truetype');
}
@font-face {
  font-family: 'Spartan';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/spartan/v3/l7gAbjR61M69yt8Z8w6FZf9WoBxdBrFivm6O.ttf) format('truetype');
}
body {
  min-height: 100vh;
  height: 100%;
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
body.no-scroll {
  overflow: hidden;
  max-height: 100vh;
}
body ul,
body ol,
body p,
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  margin: 0;
  padding: 0;
}
body a {
  text-decoration: none !important;
}
body::-webkit-scrollbar {
  width: 20px;
}
body::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}
body::-webkit-scrollbar-thumb {
  background-color: #ddd;
}
body::-webkit-scrollbar-thumb:hover {
  background-color: rgba(233, 82, 22, 0.8);
}
.alert-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.2);
  z-index: 555;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;
}
.alert-wrapper .alert-service {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  transform: translateX(8px);
  padding: 50px 0;
}
.alert-wrapper .alert-service .service-inner {
  padding: 50px;
  background: #fff;
  width: 100%;
  box-shadow: 0px 0px 32px 1px rgba(0, 0, 0, 0.15);
  text-align: center;
  border-top: 5px solid #fff;
  position: relative;
  transform: scale(0.8);
  transition: all 0.5s;
}
.alert-wrapper .alert-service .service-inner .inner-dismiss {
  top: 10px;
  right: 10px;
  position: absolute;
  border: 0;
  background: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  padding: 5px;
  outline: none;
}
.alert-wrapper .alert-service .service-inner .inner-dismiss img {
  height: 15px;
}
.alert-wrapper .alert-service .service-inner.success {
  border-top: 5px solid #78b042;
}
.alert-wrapper .alert-service .service-inner.warning {
  border-top: 5px solid #d51a27;
}
.alert-wrapper .alert-service .service-inner.info {
  border-top: 5px solid #1a88d5;
}
.alert-wrapper.active {
  opacity: 1;
  visibility: visible;
}
.alert-wrapper.active .alert-service .service-inner {
  transform: none;
}
.fixed-button {
  position: fixed;
  left: -400px;
  height: 200px;
  bottom: 25vh;
  z-index: 444;
  display: flex;
  align-items: center;
  transition: 500ms;
  animation: shake-animation 4.72s ease infinite;
  transform-origin: 50% 50%;
}
.fixed-button:hover,
.fixed-button:focus,
.fixed-button:active {
  left: 0;
}
.fixed-button .button-wrapper {
  display: flex;
  align-items: center;
}
.fixed-button .button-wrapper .service-button {
  background-color: #e95216;
  cursor: pointer;
  height: 60px;
  width: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fixed-button .button-wrapper .service-button p {
  color: #fff;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.5px;
}
.fixed-button .button-wrapper .service-box {
  width: 400px;
  height: 200px;
  background-color: #f5f5f5;
  box-shadow: 0 0 10.4px 5.6px rgba(47, 47, 47, 0.07);
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.fixed-button .button-wrapper .service-box h3 {
  margin-bottom: 10px;
  font-size: 22px;
}
.fixed-button .button-wrapper .service-box p {
  font-size: 15px;
  line-height: 26px;
}
.fixed-button .button-wrapper .service-box a {
  color: #e95216;
  font-weight: 600;
  display: block;
  margin-top: 10px;
  font-size: 17px;
}
@keyframes shake-animation {
  0% {
    transform: translate(0, 0);
  }
  1.78571% {
    transform: translate(5px, 0);
  }
  3.57143% {
    transform: translate(0, 0);
  }
  5.35714% {
    transform: translate(5px, 0);
  }
  7.14286% {
    transform: translate(0, 0);
  }
  8.92857% {
    transform: translate(5px, 0);
  }
  10.71429% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
nav.section-header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 555;
  width: 100%;
  padding: 0 50px;
}
nav.section-header .header-flexbox {
  padding: 25px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
nav.section-header .header-flexbox .flexbox-logo img {
  width: 220px;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close {
  position: absolute;
  display: none;
  width: 100%;
  top: 20px;
  left: 0;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close .container {
  display: flex;
  justify-content: flex-end;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close .container:before,
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close .container:after {
  content: none;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button {
  outline: none;
  border: none;
  background: none;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar {
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  background: #222;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar:nth-child(1) {
  transform: rotate(45deg);
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar:nth-child(2) {
  transform: rotate(-45deg);
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li {
  padding: 0 20px;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a {
  color: #000;
  font-weight: 500;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  padding-bottom: 5px;
  transition: all 250ms;
  font-size: 14px;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li:hover a {
  color: #e95216;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.active.desktop .dropdown-toggle {
  color: #e95216;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.active a {
  color: #e95216;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.featured {
  padding: 10px 15px;
  background-color: rgba(233, 82, 22, 0.85);
  transition: all 250ms;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.featured a {
  font-weight: 700;
  color: #fff;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.featured:hover {
  background-color: #e95216;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.social-media {
  padding: 0 0 0 20px;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.social-media img {
  width: 40px;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.desktop .dropdown-toggle {
  outline: none;
  border: none;
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  color: #000;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: color 350ms;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.desktop .dropdown-toggle:hover {
  color: #e95216;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.desktop .dropdown-toggle::after {
  display: none;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.desktop .slideIn {
  animation-name: slideIn;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.desktop .animate {
  animation-duration: 400ms;
  animation-fill-mode: both;
}
@keyframes slideIn {
  0% {
    transform: translateY(0);
    opacity: 0;
  }
  100% {
    transform: translateY(25px);
    opacity: 1;
  }
  0% {
    transform: translateY(0);
    opacity: 0;
  }
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.desktop .dropdown-menu {
  position: relative;
  border-radius: 0;
  border: none;
  margin-top: 15px;
  padding: 0;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.desktop .dropdown-menu.show {
  left: -95px !important;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.desktop .dropdown-menu:before {
  content: "";
  display: block;
  position: absolute;
  left: 125px;
  top: -8px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent rgba(0, 0, 0, 0.75) transparent;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.desktop .dropdown-menu .menu-wrapper {
  background: rgba(0, 0, 0, 0.75);
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.desktop .dropdown-menu .dropdown-item {
  position: relative;
  padding: 5px 10px;
  font-size: 12.5px;
  font-weight: 500;
  transition: all 0.3s;
  transform: none !important;
  width: auto;
  color: #fff;
  text-align: center;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.desktop .dropdown-menu .dropdown-item:not(:last-of-type):after {
  position: absolute;
  top: 7px;
  right: 0;
  content: "";
  display: block;
  width: 1px;
  height: 15px;
  background-color: #fff;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.desktop .dropdown-menu .dropdown-item:hover {
  color: #e95216;
  background: transparent;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.mobile {
  display: none;
  flex-direction: column;
  align-items: center;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.mobile .collapse-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.mobile .collapse-list li {
  padding: 3px 0;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.mobile .collapse-list li a {
  font-size: 14px;
  font-weight: 400;
  color: #000;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.mobile .collapse-list li a:active {
  background-color: transparent;
}
nav.section-header .header-flexbox .nav-button {
  padding: 10px;
  border: none;
  background: none;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  outline: none;
  display: none;
}
nav.section-header .header-flexbox .nav-button .button-bar {
  height: 2px;
  width: 24px;
  background: #e95216;
  border-radius: 10px;
  transition: all 0.4s;
}
nav.section-header .header-flexbox .nav-button .button-bar:nth-child(2) {
  margin-top: 4px;
  width: 27px;
}
nav.section-header .header-flexbox .nav-button .button-bar:nth-child(3) {
  margin-top: 4px;
  width: 30px;
}
nav.section-header .header-flexbox .nav-button:hover .button-bar,
nav.section-header .header-flexbox .nav-button:focus .button-bar {
  width: 30px !important;
}
.btn {
  background-color: transparent;
  border-radius: 0;
  padding: 10px 28px;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1.5px;
  transition: all 350ms;
  border: 1px solid transparent;
}
.btn.btn-default {
  background-color: #2c2b29;
  color: #fff;
}
.btn.btn-default:hover {
  background-color: transparent;
  border: 1px solid #2c2b29;
  color: #e95216;
}
.btn.btn-arrow {
  font-size: 16px;
  padding: 0;
  font-weight: 500;
  text-transform: none;
  color: #000;
  display: flex;
  align-items: center;
}
.btn.btn-arrow.light {
  color: #fff;
}
.btn.btn-arrow.light span {
  color: #fff;
}
.btn.btn-arrow span {
  font-size: 40px;
  font-weight: 600;
  color: #000;
  transition: color 350ms;
  display: inline-block;
  transform: translateX(8px);
  transition: all 350ms;
}
.btn.btn-arrow:hover {
  color: #e95216;
}
.btn.btn-arrow:hover span {
  color: #e95216;
  transform: translateX(12px);
}
.offer-element {
  transform: translateY(50px);
  display: flex;
  justify-content: space-between;
}
.offer-element .offer-item {
  position: relative;
  width: 30%;
  padding-top: 24%;
  overflow: hidden;
  color: #fff;
  box-shadow: 0 19px 20px rgba(0, 0, 0, 0.1), 0 15px 12px rgba(0, 0, 0, 0.1);
  transition: all 250ms;
}
.offer-element .offer-item .offer-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
}
.offer-element .offer-item h2 {
  margin-bottom: 20px;
  font-size: 28px;
}
.offer-element .offer-item:hover .overlay {
  opacity: 1;
}
.offer-element .offer-item .overlay {
  position: absolute;
  z-index: 11;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  transition: all 350ms;
}
.offer-element .offer-item .overlay span {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
}
.offer-element .offer-item:first-of-type {
  background-color: rgba(58, 95, 137, 0.9);
}
.offer-element .offer-item:first-of-type .overlay {
  background-color: #3a5f89;
}
.offer-element .offer-item:nth-of-type(2) {
  background-color: rgba(44, 43, 41, 0.9);
}
.offer-element .offer-item:nth-of-type(2) .overlay {
  background-color: #2c2b29;
}
.offer-element .offer-item:nth-of-type(3) {
  background-color: rgba(233, 82, 22, 0.9);
}
.offer-element .offer-item:nth-of-type(3) .overlay {
  background-color: #e95216;
}
.section-heading {
  width: 100%;
}
.section-heading h2,
.section-heading h3 {
  margin-bottom: 20px;
  line-height: 42px;
}
.section-heading h2 span,
.section-heading h3 span {
  position: relative;
  display: inline-block;
  font-size: 27px;
}
.section-heading h2 span:before,
.section-heading h3 span:before {
  position: absolute;
  z-index: -1;
  bottom: 5px;
  left: 2px;
  content: "";
  width: 103%;
  height: 12px;
  background-color: #e95216;
}
.main-section-heading {
  padding: 180px 0 80px 0;
  width: 70%;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Spartan", sans-serif;
}
.main-section-heading img {
  width: 80px;
  margin-bottom: 30px;
}
.main-section-heading h1 {
  font-size: 46px;
  font-weight: 700;
  margin-bottom: 20px;
}
.main-section-heading p {
  line-height: 28px;
  font-size: 22px;
  letter-spacing: 0.5px;
  text-align: center;
}
.section-description {
  padding-top: 80px;
  text-align: center;
}
.section-description h2 {
  font-size: 42px;
  font-weight: 700;
  margin-bottom: 20px;
}
.section-description h2 img {
  width: 150px;
  transform: translate(15px, -5px);
}
.section-description h2 strong {
  color: #e95216;
}
.section-description p {
  padding: 0 7%;
  line-height: 28px;
}
.section-description p:not(:first-of-type) {
  margin-top: 10px;
}
.section-cooperation {
  padding: 80px 0 60px 0;
}
.section-cooperation h2 {
  text-align: center;
}
.section-cooperation .owl-carousel-cooperation {
  margin-top: 40px;
}
.section-cooperation .owl-carousel-cooperation .owl-stage-outer,
.section-cooperation .owl-carousel-cooperation .owl-stage,
.section-cooperation .owl-carousel-cooperation .owl-item {
  height: 150px;
}
.section-cooperation .owl-carousel-cooperation .item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.section-cooperation .owl-carousel-cooperation .item img {
  width: 50%;
  height: 100%;
  object-fit: contain;
}
section.section-welcome .welcome-background {
  background: url(../img/backgrounds/bg.jpg) 0 15% no-repeat;
  background-size: cover;
}
section.section-welcome .welcome-wrapper {
  padding: 100px 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
section.section-welcome .welcome-wrapper .row-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding: 1% 2.5%;
}
section.section-welcome .welcome-wrapper .row-wrapper .welcome-element {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #000;
  background-color: #f4f4f4;
  transition: background-color 350ms;
}
section.section-welcome .welcome-wrapper .row-wrapper .welcome-element:hover {
  background-color: #ebebeb;
}
section.section-welcome .welcome-wrapper .row-wrapper .welcome-element:hover span {
  color: #e95216;
}
section.section-welcome .welcome-wrapper .row-wrapper .welcome-element .badge {
  position: absolute;
  top: 20px;
  right: 0;
  min-width: 200px;
  height: 30px;
  padding: 5px 10px;
  background-color: #e95216;
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.5px;
  font-weight: 600;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
section.section-welcome .welcome-wrapper .row-wrapper .welcome-element header {
  width: 45%;
}
section.section-welcome .welcome-wrapper .row-wrapper .welcome-element .element-image {
  position: relative;
  width: 50%;
}
section.section-welcome .welcome-wrapper .row-wrapper .welcome-element .element-image img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
}
section.section-welcome .welcome-wrapper .row-wrapper .welcome-element h2 {
  position: relative;
  z-index: 1;
  margin-bottom: 10px;
  font-weight: 600;
}
section.section-welcome .welcome-wrapper .row-wrapper .welcome-element span {
  display: inline-block;
  margin-top: 20px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.5px;
  transition: color 350ms;
}
section.section-welcome .welcome-wrapper .row-wrapper.top .welcome-element {
  text-align: right;
  padding-right: 30px;
}
section.section-welcome .welcome-wrapper .row-wrapper.top .welcome-element header {
  margin-top: 30px;
}
section.section-welcome .welcome-wrapper .row-wrapper.top .welcome-element h2 {
  font-size: 28px;
  line-height: 34px;
}
section.section-welcome .welcome-wrapper .row-wrapper.top .welcome-element span {
  font-size: 14px;
}
section.section-welcome .welcome-wrapper .row-wrapper.top .welcome-element .element-image {
  padding-top: 90%;
}
section.section-welcome .welcome-wrapper .row-wrapper.top .welcome-element:first-of-type {
  width: 32%;
}
section.section-welcome .welcome-wrapper .row-wrapper.top .welcome-element.img-background {
  background: url(../img/backgrounds//bg.jpg) 0 28% no-repeat;
  background-size: cover;
  width: 66%;
}
section.section-welcome .welcome-wrapper .row-wrapper.bottom .welcome-element {
  width: 32%;
  padding-left: 30px;
}
section.section-welcome .welcome-wrapper .row-wrapper.bottom .welcome-element h2 {
  font-size: 20px;
  line-height: 26px;
}
section.section-welcome .welcome-wrapper .row-wrapper.bottom .welcome-element p {
  font-size: 14px;
}
section.section-welcome .welcome-wrapper .row-wrapper.bottom .welcome-element .element-image {
  padding-top: 50%;
}
section.section-welcome .welcome-wrapper .row-wrapper.bottom .welcome-element span {
  font-size: 12px;
}
section.section-welcome .welcome-wrapper .row-wrapper.bottom .welcome-element .element-image img {
  height: 100%;
  object-fit: cover;
}
section.section-about {
  position: relative;
  overflow: hidden;
}
section.section-about.p-100 {
  padding: 100px 0;
}
section.section-about .section-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
section.section-about .section-wrapper.reverse {
  flex-direction: row-reverse;
}
section.section-about .section-wrapper .section-text {
  width: 45%;
}
section.section-about .section-wrapper .section-text h3 {
  font-weight: 700;
  font-size: 40px;
  line-height: 42px;
  margin-bottom: 30px;
}
section.section-about .section-wrapper .section-text h3 span {
  position: relative;
  display: inline-block;
  font-size: 27px;
}
section.section-about .section-wrapper .section-text h3 span:before {
  position: absolute;
  z-index: -1;
  bottom: 5px;
  left: 2px;
  content: "";
  width: 103%;
  height: 12px;
  background-color: #e95216;
}
section.section-about .section-wrapper .section-text p {
  line-height: 28px;
}
section.section-about .section-wrapper .section-text p:not(:first-of-type) {
  margin-top: 10px;
}
section.section-about .section-wrapper .section-text .btn-default {
  margin-top: 30px;
}
section.section-about .section-wrapper .section-img {
  position: relative;
  width: 50%;
  padding-top: 40%;
  background-color: #f4f4f4;
}
section.section-about .section-wrapper .section-img img {
  position: absolute;
  top: 0;
  left: 0;
  padding: 15px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
section.section-about .img-absolute {
  position: absolute;
  z-index: -1;
  transform: scale(-1, 1);
  right: -100px;
  bottom: -3%;
  opacity: 0.05;
  width: 400px;
}
section.section-offer {
  position: relative;
  margin-top: 40px;
}
section.section-offer .mobile {
  display: none;
  text-align: center;
  margin-bottom: 10px;
  font-weight: 700;
}
section.section-offer .bg-wrapper {
  position: absolute;
  top: -50px;
  z-index: -1;
  background-color: #f4f4f4;
  width: 53%;
  height: 112%;
}
section.section-offer .owl-carousel-offer {
  height: 50%;
  width: 100%;
}
section.section-offer .owl-carousel-offer .owl-stage-outer,
section.section-offer .owl-carousel-offer .owl-stage,
section.section-offer .owl-carousel-offer .owl-item {
  height: 100%;
}
section.section-offer .owl-carousel-offer .owl-dots {
  display: none;
}
section.section-offer .owl-carousel-offer .owl-nav {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
section.section-offer .owl-carousel-offer .owl-nav .owl-prev,
section.section-offer .owl-carousel-offer .owl-nav .owl-next {
  outline: none;
}
section.section-offer .owl-carousel-offer .owl-nav .owl-prev span,
section.section-offer .owl-carousel-offer .owl-nav .owl-next span {
  font-size: 40px;
  line-height: 30px;
  font-weight: 600;
  color: #000;
}
section.section-offer .owl-carousel-offer .owl-nav .owl-prev:first-of-type span,
section.section-offer .owl-carousel-offer .owl-nav .owl-next:first-of-type span {
  margin-right: 10px;
}
section.section-offer .owl-carousel-offer .owl-nav .owl-prev:nth-of-type(2) span,
section.section-offer .owl-carousel-offer .owl-nav .owl-next:nth-of-type(2) span {
  margin-left: 10px;
}
section.section-offer .owl-carousel-offer .item {
  display: flex;
  align-items: center;
}
section.section-offer .owl-carousel-offer .item .item-img {
  position: relative;
  width: 45%;
  padding-top: 25%;
}
section.section-offer .owl-carousel-offer .item .item-img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 20px 20px 20px 0;
}
section.section-offer .owl-carousel-offer .item .item-text {
  width: 55%;
  padding: 0 250px 0 75px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
section.section-offer .owl-carousel-offer .item .item-text h3 {
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 30px;
}
section.section-offer .owl-carousel-offer .item .item-text p {
  line-height: 28px;
}
section.section-offer .owl-carousel-offer .item .item-text p:not(:first-of-type) {
  margin-top: 10px;
}
section.section-offer .owl-carousel-offer .item .item-text .btn-default {
  margin-top: 30px;
}
section.section-opinion {
  position: relative;
  margin: 100px 0;
}
section.section-opinion .section-parallax {
  background-image: url("../img/@temp/parallax.jpg");
  height: 500px;
  opacity: 0.5;
  width: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
section.section-opinion .owl-carousel-opinion {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
section.section-opinion .owl-carousel-opinion .owl-stage-outer,
section.section-opinion .owl-carousel-opinion .owl-stage,
section.section-opinion .owl-carousel-opinion .owl-item {
  height: 100%;
}
section.section-opinion .owl-carousel-opinion .owl-nav {
  position: relative;
  bottom: 150px;
  display: flex;
  justify-content: center;
}
section.section-opinion .owl-carousel-opinion .owl-nav .owl-prev,
section.section-opinion .owl-carousel-opinion .owl-nav .owl-next {
  outline: none;
}
section.section-opinion .owl-carousel-opinion .owl-nav .owl-prev span,
section.section-opinion .owl-carousel-opinion .owl-nav .owl-next span {
  font-size: 40px;
  font-weight: 600;
  color: #000;
  margin: 0 10px;
}
section.section-opinion .owl-carousel-opinion .item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 40%;
  margin: 0 auto;
  height: 100%;
}
section.section-opinion .owl-carousel-opinion .item h4 {
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 28px;
}
section.section-opinion .owl-carousel-opinion .item p {
  line-height: 28px;
}
section.section-opinion .owl-carousel-opinion .item span {
  display: block;
  font-weight: 600;
  font-size: 20px;
  margin-top: 20px;
}
section.section-cta {
  margin: 100px 0 40px 0;
  padding: 40px 0 100px 0;
}
section.section-cta .section-wrapper {
  position: relative;
  background-color: #2c2b29;
}
section.section-cta .section-wrapper .img-absolute {
  position: absolute;
  height: 100%;
  width: 50%;
  opacity: 0.1;
  top: 0;
  left: 0;
}
section.section-cta .section-wrapper .cta-content {
  position: relative;
  display: flex;
  align-items: center;
}
section.section-cta .section-wrapper .cta-content .cta-text {
  background-color: #f4f4f4;
  color: #000;
  width: 47%;
  padding: 40px;
  transform: translateY(40px);
  box-shadow: 0 19px 20px rgba(0, 0, 0, 0.1), 0 15px 12px rgba(0, 0, 0, 0.1);
}
section.section-cta .section-wrapper .cta-content .cta-text h2 {
  font-size: 28px;
  margin-bottom: 20px;
  font-weight: 600;
  line-height: 42px;
}
section.section-cta .section-wrapper .cta-content .cta-text h2 strong {
  color: #e95216;
  font-size: 34px;
}
section.section-cta .section-wrapper .cta-content .cta-text .btn-arrow {
  margin-top: 10px;
}
section.section-cta .section-wrapper .cta-content .cta-img {
  position: absolute;
  width: 45%;
  right: -70px;
}
section.section-cta .section-wrapper .cta-content .cta-img img {
  width: 85%;
  height: 85%;
  object-fit: cover;
}
.section-process {
  padding-top: 80px;
  display: flex;
  flex-direction: column;
}
.section-process .process-flexbox {
  padding-top: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.section-process .process-flexbox img {
  width: 30px;
}
.section-process .process-flexbox .process-stage {
  width: calc(85% / 4);
  height: 100%;
  text-align: center;
  cursor: pointer;
}
.section-process .process-flexbox .process-stage button {
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 18px;
}
.section-process .process-flexbox .process-stage button .icon {
  width: 45px;
  margin: 0 auto;
  transition: all 350ms;
}
.section-process .process-flexbox .process-stage button .step {
  font-size: 13.5px;
  font-weight: 500;
  display: block;
  margin: 15px 0 3px 0;
}
.section-process .process-flexbox .process-stage:hover .icon {
  transform: translateY(-8px);
}
.section-process .process-flexbox .process-stage:hover .text {
  display: block;
}
.section-process .process-flexbox .process-stage .modal-content {
  padding-bottom: 20px;
}
.section-process .process-flexbox .process-stage .modal-content .modal-header {
  align-items: center;
}
.section-process .process-flexbox .process-stage .modal-content .modal-header .close:hover span {
  color: #3a5f89;
}
.section-process .process-flexbox .process-stage .modal-content .modal-body {
  text-align: left;
  padding: 20px;
}
.section-process .process-flexbox .process-stage .modal-content .modal-body p {
  line-height: 26px;
  font-size: 15px;
}
.section-process .process-flexbox .process-stage .modal-content .modal-body p:not(:first-of-type) {
  margin-top: 10px;
}
.section-process .process-flexbox .process-stage .modal-content .modal-body ul,
.section-process .process-flexbox .process-stage .modal-content .modal-body ol {
  list-style: decimal;
  margin: 10px 0 10px 30px;
}
.section-process .process-flexbox .process-stage .modal-content .modal-body ul li,
.section-process .process-flexbox .process-stage .modal-content .modal-body ol li {
  padding: 5px 0;
  font-size: 15px;
}
.section-process .process-description {
  padding-top: 20px;
  text-align: center;
}
.process-cta {
  position: relative;
  overflow: hidden;
  background-color: #f5f5f5;
  margin-top: 80px;
  padding: 70px 0;
}
.process-cta .img-absolute {
  position: absolute;
  top: -20px;
  left: -20px;
  opacity: 0.3;
}
.process-cta .cta-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.process-cta .cta-wrapper h3 {
  font-weight: 700;
  font-size: 32px;
}
.process-cta .cta-wrapper .btn-default {
  margin-top: 40px;
}
.process-cta .cta-wrapper.cta-center {
  align-items: center;
}
.section-background {
  background: url(../img/backgrounds/bg3.jpg) 0 65% no-repeat;
  background-size: cover;
}
.section-gallery {
  padding-top: 60px;
}
.section-gallery .gallery-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.section-gallery .gallery-wrapper a {
  position: relative;
  width: 23%;
  padding-top: 23%;
  margin-top: 2%;
  box-shadow: 5px 0px 10px 0px rgba(0, 0, 0, 0.15);
  transition: transform 350ms;
}
.section-gallery .gallery-wrapper a img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  object-fit: cover;
  object-position: 20% 10%;
  transition: all 350ms;
}
.section-gallery .gallery-wrapper a:hover {
  transform: translateY(-8px);
}
.section-gallery .gallery-wrapper .box-hidden {
  width: 23%;
  opacity: 0;
  visibility: hidden;
}
section.o-nas-section-content {
  padding-bottom: 40px;
}
section.o-nas-section-content h2 {
  font-size: 40px;
  font-weight: 700;
}
section.o-nas-section-content .section-wrapper {
  padding-top: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
section.o-nas-section-content .section-wrapper.reverse {
  flex-direction: row-reverse;
}
section.o-nas-section-content .section-wrapper .section-text {
  width: 50%;
}
section.o-nas-section-content .section-wrapper .section-text p {
  line-height: 28px;
}
section.o-nas-section-content .section-wrapper .section-text p:not(:first-of-type) {
  margin-top: 10px;
}
section.o-nas-section-content .section-wrapper .section-text ul {
  list-style: none;
  padding: 10px 0 0 20px;
}
section.o-nas-section-content .section-wrapper .section-text ul li {
  position: relative;
  font-size: 15px;
  padding: 5px 0 5px 15px;
}
section.o-nas-section-content .section-wrapper .section-text ul li:before {
  position: absolute;
  content: "";
  top: 10px;
  left: -10px;
  width: 12px;
  height: 12px;
  background-color: #e95216;
}
section.o-nas-section-content .section-wrapper .section-heading {
  width: 45%;
  margin: 0;
}
section.o-nas-section-content .section-wrapper .section-heading h2 {
  margin: 0;
}
section.o-nas-section-content .section-timeline {
  position: relative;
  margin-top: 150px;
}
section.o-nas-section-content .section-timeline:before {
  position: absolute;
  content: "";
  top: 0;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 3px;
  background-color: #e95216;
}
section.o-nas-section-content .section-timeline .timeline-wrapper {
  width: calc(100% - 80px);
  margin: auto;
  position: relative;
  left: -5px;
}
section.o-nas-section-content .section-timeline .timeline-wrapper .timeline {
  width: 50%;
  padding: 35px;
  text-align: right;
  margin: -30px 0 0 auto;
}
section.o-nas-section-content .section-timeline .timeline-wrapper .timeline:nth-of-type(odd) {
  margin: -30px auto 0 0;
}
section.o-nas-section-content .section-timeline .timeline-wrapper .timeline .date {
  font-size: 32px;
  margin-bottom: 12px;
  position: relative;
}
section.o-nas-section-content .section-timeline .timeline-wrapper .timeline .date span {
  font-size: 24px;
}
section.o-nas-section-content .section-timeline .timeline-wrapper .timeline .date.active {
  font-weight: 700;
}
section.o-nas-section-content .section-timeline .timeline-wrapper .timeline .date.active:before {
  background-color: #e95216;
  transform: scale(1.1);
}
section.o-nas-section-content .section-timeline .timeline-wrapper .timeline .date:before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border: 3px solid #e95216;
  background-color: #fff;
  border-radius: 100%;
  top: 50%;
  transform: translateY(-50%);
  right: -58px;
  z-index: 1000;
  transition: all 350ms;
}
section.o-nas-section-content .section-timeline .timeline-wrapper .timeline .date.big:before {
  width: 38px;
  height: 38px;
}
section.o-nas-section-content .section-timeline .timeline-wrapper .timeline .description p {
  line-height: 28px;
}
section.o-nas-section-content .section-timeline .timeline-wrapper .timeline .description p strong {
  font-size: 17px;
}
section.o-nas-section-content .section-timeline .timeline-wrapper .timeline:nth-child(even) {
  text-align: left;
}
section.o-nas-section-content .section-timeline .timeline-wrapper .timeline:nth-child(even) .date:before {
  left: -40px;
}
section.o-nas-section-content .section-timeline .timeline-wrapper .timeline:nth-child(even) .date.big:before {
  left: -50px;
}
section.o-nas-section-content .section-cooperation {
  padding: 80px 0 0 0;
}
.section-features {
  background-color: #f4f4f4;
  margin-top: 80px;
  padding: 80px 0;
  text-align: center;
}
.section-features .features-wrapper {
  padding-top: 50px;
  display: flex;
  justify-content: space-between;
}
.section-features .features-wrapper .feature {
  width: 24%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.section-features .features-wrapper .feature h3 {
  font-size: 16px;
  line-height: 24px;
}
.section-features .features-wrapper .feature img {
  width: 38px;
  margin-bottom: 15px;
  transition: transform 350ms;
}
.section-features .features-wrapper .feature:hover img {
  transform: translateY(-8px);
}
.section-item-list {
  padding-top: 80px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.section-item-list.leasing .leasing-wrapper {
  display: flex;
  width: 100%;
}
.section-item-list.leasing .leasing-wrapper .navigation {
  margin-top: 15px;
  width: 20%;
  border-right: 1px solid #e95216;
}
.section-item-list.leasing .leasing-wrapper .navigation p {
  font-size: 22px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0 0 10px 0;
}
.section-item-list.leasing .leasing-wrapper .navigation ul {
  flex-direction: column;
  border: none;
}
.section-item-list.leasing .leasing-wrapper .navigation ul li {
  padding: 10px 0;
  transition: all 300ms;
  transform: translateZ(0);
}
.section-item-list.leasing .leasing-wrapper .navigation ul li a {
  color: #222;
  padding: 5px 0;
  transition: all 300ms;
}
.section-item-list.leasing .leasing-wrapper .navigation ul li a.active {
  color: #000;
  font-weight: 600;
}
.section-item-list.leasing .leasing-wrapper .navigation ul li:hover {
  transform: translatex(3px);
}
.section-item-list.leasing .leasing-wrapper .navigation ul li:hover a {
  color: #e95216;
}
.section-item-list.leasing .leasing-wrapper .leasing-content {
  width: 80%;
}
.section-item-list.leasing .leasing-wrapper .leasing-description {
  padding-left: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.section-item-list.leasing .leasing-wrapper .leasing-description h2,
.section-item-list.leasing .leasing-wrapper .leasing-description h3 {
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #222;
  margin: 20px 0 5px 0;
  font-size: 18px;
}
.section-item-list.leasing .leasing-wrapper .leasing-description ul {
  flex-direction: column;
  border: none;
  padding: 10px 0 0 20px;
}
.section-item-list.leasing .leasing-wrapper .leasing-description ul li {
  padding: 5px 0;
  transition: all 300ms;
}
.section-item-list.leasing .leasing-wrapper .leasing-description ul li a {
  color: #222;
  padding: 5px 0;
  transition: all 300ms;
}
.section-item-list.leasing .leasing-wrapper .leasing-description ul li a.active {
  color: #e95216;
}
.section-item-list.leasing .leasing-wrapper .leasing-description ul li:hover {
  transform: translatex(3px);
}
.section-item-list.leasing .leasing-wrapper .leasing-description ul li:hover a {
  color: #e95216;
}
.section-item-list.leasing .leasing-wrapper .leasing-description p {
  font-size: 15px;
  line-height: 28px;
  margin-top: 10px;
}
.section-item-list .items-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}
.section-item-list .items-wrapper.items-lg .item {
  position: relative;
  width: 31.5%;
  padding-top: 22%;
  margin: 10px;
}
.section-item-list .items-wrapper .item {
  background-color: #f4f4f4;
  color: #000;
  position: relative;
  width: 23%;
  padding-top: 20%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.03), 0 6px 6px rgba(0, 0, 0, 0.2);
  margin: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.section-item-list .items-wrapper .item:nth-of-type(even) {
  background: #ddd;
}
.section-item-list .items-wrapper .item.last-tile {
  width: 30%;
}
.section-item-list .items-wrapper .item.last-tile h3 {
  font-weight: 700;
  font-weight: 28px;
}
.section-item-list .items-wrapper .item .item-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 95%;
  height: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.section-item-list .items-wrapper .item .item-text h3 {
  font-size: 22px;
  font-weight: 500;
}
section.offer-section-content {
  padding-bottom: 80px;
}
section.offer-section-content .section-heading h2,
section.offer-section-content .section-heading h3 {
  font-size: 40px;
  font-weight: 700;
}
section.offer-section-content .section-table {
  padding-top: 60px;
}
section.offer-section-content .section-table table {
  padding-top: 20px;
  border-collapse: collapse;
  width: 100%;
}
section.offer-section-content .section-table th,
section.offer-section-content .section-table td {
  text-align: center;
  padding: 10px 5px;
  border-bottom: 1px solid #e95216;
  font-size: 12px;
}
section.offer-section-content .section-table td {
  min-width: 130px;
}
section.offer-section-content .section-table .btn-default {
  padding: 10px 12px;
  font-size: 11px;
}
section.offer-section-content .section-table .section-heading {
  text-align: center;
  margin-bottom: 30px;
}
section.offer-section-content .section-table .modal-content {
  padding-bottom: 20px;
}
section.offer-section-content .section-table .modal-content .modal-header {
  align-items: center;
}
section.offer-section-content .section-table .modal-content .modal-header .close:hover span {
  color: #3a5f89;
}
section.offer-section-content .section-table .modal-content .modal-body {
  padding: 20px;
  display: flex;
  justify-content: center;
}
section.offer-section-content .section-table .modal-content .modal-body .btn-default {
  display: block;
  margin: 10px 0;
}
section.offer-section-content .section-table .modal-content .modal-body p {
  font-size: 17px;
}
section.offer-section-content .section-download {
  position: relative;
  overflow: hidden;
  background-color: #f5f5f5;
  margin-top: 80px;
  padding: 70px 0;
}
section.offer-section-content .section-download .img-absolute {
  position: absolute;
  top: -20px;
  left: -20px;
  opacity: 0.3;
}
section.offer-section-content .section-download .download-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
section.offer-section-content .section-download .download-wrapper h3 {
  font-weight: 700;
  font-size: 32px;
}
section.offer-section-content .section-download .download-wrapper .btn-default {
  margin-top: 40px;
}
section.offer-section-content .section-products {
  padding-top: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
section.offer-section-content .section-products.reverse {
  flex-direction: row-reverse;
}
section.offer-section-content .section-products .owl-carousel-products {
  display: flex;
  align-items: center;
  width: 40%;
}
section.offer-section-content .section-products .owl-carousel-products .owl-stage {
  height: 400px;
}
section.offer-section-content .section-products .owl-carousel-products .owl-item {
  position: relative;
  height: 100%;
}
section.offer-section-content .section-products .owl-carousel-products .owl-nav {
  position: absolute;
  bottom: 0;
  width: 80px;
  right: 10px;
  display: flex;
  justify-content: center;
  background-color: #f4f4f4;
}
section.offer-section-content .section-products .owl-carousel-products .owl-nav .owl-prev,
section.offer-section-content .section-products .owl-carousel-products .owl-nav .owl-next {
  outline: none;
}
section.offer-section-content .section-products .owl-carousel-products .owl-nav .owl-prev span,
section.offer-section-content .section-products .owl-carousel-products .owl-nav .owl-next span {
  font-size: 40px;
  font-weight: 600;
  color: #000;
}
section.offer-section-content .section-products .owl-carousel-products .owl-nav .owl-prev:first-of-type span,
section.offer-section-content .section-products .owl-carousel-products .owl-nav .owl-next:first-of-type span {
  margin-right: 10px;
}
section.offer-section-content .section-products .owl-carousel-products .owl-nav .owl-prev:nth-of-type(2) span,
section.offer-section-content .section-products .owl-carousel-products .owl-nav .owl-next:nth-of-type(2) span {
  margin-left: 10px;
}
section.offer-section-content .section-products .owl-carousel-products .owl-products {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;
  display: flex;
  align-items: center;
  justify-content: center;
}
section.offer-section-content .section-products .owl-carousel-products .owl-products img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 90%;
  width: 90%;
  object-fit: contain;
  background-color: #fff;
}
section.offer-section-content .section-products .products-image {
  position: relative;
  width: 40%;
  padding-top: 40%;
  background-color: #f4f4f4;
}
section.offer-section-content .section-products .products-image img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 90%;
  width: 90%;
  object-fit: cover;
  object-position: top center;
}
section.offer-section-content .section-products .products-text {
  width: 55%;
}
section.offer-section-content .section-products .products-text nav a {
  color: #000;
  transition: color 350ms;
}
section.offer-section-content .section-products .products-text nav a:hover {
  color: #e95216;
}
section.offer-section-content .section-products .products-text ul {
  list-style: none;
  padding: 10px 0 0 12px;
  text-align: left;
}
section.offer-section-content .section-products .products-text ul li {
  position: relative;
  font-size: 14px;
  padding: 5px 0 5px 15px;
}
section.offer-section-content .section-products .products-text ul li:before {
  position: absolute;
  content: "";
  top: 10px;
  left: -10px;
  width: 12px;
  height: 12px;
  background-color: #e95216;
}
section.offer-section-content .section-products .products-text p {
  line-height: 28px;
}
section.offer-section-content .section-products .products-text p:not(:first-of-type) {
  margin-top: 10px;
}
section.offer-section-content .products-cta {
  position: relative;
  background-color: #2c2b29;
  padding: 40px 0;
  margin-top: 70px;
}
section.offer-section-content .products-cta .img-absolute {
  position: absolute;
  height: 100%;
  width: 50%;
  opacity: 0.1;
  top: 0;
  left: 0;
}
section.offer-section-content .products-cta .cta-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
section.offer-section-content .products-cta .cta-wrapper .cta-heading {
  width: 52%;
  text-align: right;
  margin-right: 150px;
}
section.offer-section-content .products-cta .cta-wrapper .cta-heading h3 {
  color: #e95216;
  font-size: 46px;
  font-weight: 800;
}
section.offer-section-content .products-cta .cta-wrapper .text {
  width: 48%;
}
section.offer-section-content .products-cta .cta-wrapper .text h2 {
  font-size: 36px;
  font-weight: 700;
  line-height: 46px;
  margin-bottom: 20px;
}
section.offer-section-content .products-cta .cta-wrapper .text h2 strong {
  color: #e95216;
}
section.offer-section-content .products-cta .cta-wrapper .text p {
  margin-top: 10px;
}
section.offer-section-content .products-cta .cta-wrapper .text .btn-arrow {
  margin-top: 30px;
}
section.offer-section-content .section-distributors {
  padding-top: 80px;
}
section.offer-section-content .section-distributors h2 {
  font-size: 42px;
  font-weight: 700;
  margin-bottom: 20px;
}
section.offer-section-content .section-distributors .distributors-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
section.offer-section-content .section-distributors .image img {
  width: 100%;
}
section.offer-section-content .section-distributors .distributors-inner {
  width: 33%;
  margin-top: 40px;
}
section.offer-section-content .section-distributors .distributors-inner h3 {
  font-size: 22px;
  margin-bottom: 30px;
}
section.offer-section-content .section-distributors .distributors-inner h4 {
  font-size: 16px;
  font-weight: 700;
  margin-top: 15px;
}
section.offer-section-content .section-distributors .distributors-inner img {
  margin-top: 40px;
  width: 80%;
}
section.offer-section-content .section-distributors .distributors-inner ul {
  list-style: none;
  padding: 10px 0 0 10px;
}
section.offer-section-content .section-distributors .distributors-inner ul li {
  position: relative;
  font-size: 16px;
  padding: 5px 0 5px 15px;
}
section.offer-section-content .section-distributors .distributors-inner ul li:before {
  position: absolute;
  content: "";
  top: 12px;
  left: -8px;
  width: 10px;
  height: 10px;
  background-color: #e95216;
}
section.offer-section-content .section-distributors .distributors-inner ul li span {
  font-weight: 700;
}
section.offer-section-content .section-cooperation {
  margin-top: 70px;
  background-color: #f4f4f4;
}
section.realizacje-section-content,
section.realizacja-section-content {
  padding-bottom: 80px;
}
section.realizacje-section-content .section-realizations,
section.realizacja-section-content .section-realizations {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
section.realizacje-section-content .section-realizations .realization,
section.realizacja-section-content .section-realizations .realization {
  margin-top: 50px;
  width: 48%;
  display: flex;
}
section.realizacje-section-content .section-realizations .realization .image,
section.realizacja-section-content .section-realizations .realization .image {
  position: relative;
  width: 50%;
  padding-top: 50%;
  transition: all 350ms;
  background-color: #f5f5f5;
  padding: 10px;
  min-height: 220px;
}
section.realizacje-section-content .section-realizations .realization .image img,
section.realizacja-section-content .section-realizations .realization .image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 8px;
}
section.realizacje-section-content .section-realizations .realization .description,
section.realizacja-section-content .section-realizations .realization .description {
  width: 50%;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
section.realizacje-section-content .section-realizations .realization .description h3,
section.realizacja-section-content .section-realizations .realization .description h3 {
  font-size: 18px;
  margin-bottom: 15px;
}
section.realizacje-section-content .section-realizations .realization .description p,
section.realizacja-section-content .section-realizations .realization .description p {
  font-size: 15px;
  line-height: 26px;
}
section.realizacje-section-content .section-realizations .realization .description .btn-default,
section.realizacja-section-content .section-realizations .realization .description .btn-default {
  margin-top: 20px;
}
section.realizacje-section-content .section-realizations .realization:hover .image,
section.realizacja-section-content .section-realizations .realization:hover .image {
  opacity: 0.7;
}
section.realizacje-section-content .btn-default,
section.realizacja-section-content .btn-default {
  margin-top: 40px;
}
section.static-page-section-content {
  padding-bottom: 80px;
}
section.static-page-section-content .section-description {
  text-align: left;
}
section.static-page-section-content .section-description p {
  padding: 0;
}
section.static-page-section-content .section-description ul,
section.static-page-section-content .section-description ol {
  list-style: decimal;
  padding: 10px 0 10px 30px;
  text-align: left;
}
section.static-page-section-content .section-description ul li,
section.static-page-section-content .section-description ol li {
  font-size: 15px;
  padding: 8px 0;
}
section.faq-section-content {
  padding-bottom: 80px;
}
section.faq-section-content .accordion {
  padding-top: 70px;
  position: relative;
}
section.faq-section-content .accordion .accordion-item {
  margin-bottom: 25px;
}
section.faq-section-content .accordion .accordion-item .item-header {
  position: relative;
  padding: 0;
  padding-left: 40px;
  background: none;
  border: none;
}
section.faq-section-content .accordion .accordion-item .item-header .service-point {
  position: absolute;
  top: 0;
  left: -10px;
  margin-right: 30px;
  min-width: 30px;
  height: 30px;
  background: #e95216;
}
section.faq-section-content .accordion .accordion-item .item-header button {
  padding: 0;
  background: none;
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 0;
  text-align: left;
  text-decoration: none;
  font-size: 22px;
  font-weight: 700;
  color: #e95216;
  outline: none;
  cursor: pointer;
  border: none;
  transition: all 0.3s;
}
section.faq-section-content .accordion .accordion-item .item-header button strong {
  font-weight: 700;
  color: #e95216;
}
section.faq-section-content .accordion .accordion-item .item-header button.collapsed {
  color: #313131;
  font-weight: 300;
}
section.faq-section-content .accordion .accordion-item .item-description {
  padding: 20px 100px 0 40px;
}
section.faq-section-content .accordion .accordion-item .item-description p,
section.faq-section-content .accordion .accordion-item .item-description li {
  font-size: 16px;
  line-height: 28px;
  color: #212121;
}
section.faq-section-content .accordion .accordion-item .item-description p strong,
section.faq-section-content .accordion .accordion-item .item-description li strong {
  font-weight: 700;
  color: #e95216;
}
section.faq-section-content .accordion .accordion-item .item-description p:not(:first-of-type) {
  margin-top: 10px;
}
section.faq-section-content .accordion .accordion-item .item-description ul {
  padding-left: 17px;
}
section.faq-section-content .accordion .accordion-item:last-child {
  margin-bottom: 0;
}
section.inwestycje-section-content {
  padding-bottom: 80px;
}
section.inwestycje-section-content .section-wrapper {
  padding-top: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
section.inwestycje-section-content .section-wrapper .section-text {
  width: 45%;
}
section.inwestycje-section-content .section-wrapper .section-text h2 {
  font-weight: 700;
  font-size: 40px;
  line-height: 42px;
  margin-bottom: 40px;
}
section.inwestycje-section-content .section-wrapper .section-text h2 span {
  position: relative;
  display: inline-block;
  font-size: 27px;
}
section.inwestycje-section-content .section-wrapper .section-text h2 span:before {
  position: absolute;
  z-index: -1;
  bottom: 5px;
  left: 2px;
  content: "";
  width: 103%;
  height: 12px;
  background-color: #e95216;
}
section.inwestycje-section-content .section-wrapper .section-text p {
  line-height: 28px;
}
section.inwestycje-section-content .section-wrapper .section-text p:not(:first-of-type) {
  margin-top: 10px;
}
section.inwestycje-section-content .section-wrapper .section-text ul {
  padding: 10px 0 0 18px;
}
section.inwestycje-section-content .section-wrapper .section-text ul li {
  padding: 5px 0;
}
section.inwestycje-section-content .section-wrapper .section-img {
  position: relative;
  width: 50%;
  padding-top: 50%;
  background-color: #f4f4f4;
}
section.inwestycje-section-content .section-wrapper .section-img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 15px;
  object-fit: cover;
}
section.inwestycje-section-content .section-cta {
  position: relative;
  overflow: hidden;
  background-color: #f5f5f5;
  margin-top: 80px;
  padding: 60px 0;
}
section.inwestycje-section-content .section-cta .img-absolute {
  position: absolute;
  top: -20px;
  left: 0;
  opacity: 0.3;
}
section.inwestycje-section-content .section-cta .cta-wrapper {
  text-align: right;
  position: relative;
}
section.inwestycje-section-content .section-cta .cta-wrapper h3 {
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
}
section.inwestycje-section-content .section-cta .cta-wrapper .btn-default {
  margin-top: 40px;
}
section.inwestycje-section-content .section-offer-list .section-heading h2 {
  font-size: 40px;
  font-weight: 700;
}
section.inwestycje-section-content .section-offer-list .product {
  width: 23.5%;
  height: 220px;
  margin: 8px;
}
section.polecamy-section-content {
  padding-bottom: 70px;
  background-image: url(../img/backgrounds/bg-fixed.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
section.polecamy-section-content .section-gallery {
  padding-top: 100px;
}
section.polecamy-section-content .section-features h2 {
  font-size: 40px;
  font-weight: 700;
}
section.polecamy-section-content .section-wrapper {
  padding-top: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
section.polecamy-section-content .section-wrapper.reverse {
  flex-direction: row-reverse;
}
section.polecamy-section-content .section-wrapper.reverse .section-text {
  padding-right: 0;
}
section.polecamy-section-content .section-wrapper.reverse .section-img {
  justify-content: flex-start;
}
section.polecamy-section-content .section-wrapper.reverse .section-img img {
  transform: rotate(-10deg);
}
section.polecamy-section-content .section-wrapper .section-text,
section.polecamy-section-content .section-wrapper .section-heading {
  width: 55%;
  padding-right: 30px;
  margin: 0;
}
section.polecamy-section-content .section-wrapper .section-text h2,
section.polecamy-section-content .section-wrapper .section-heading h2 {
  font-weight: 700;
  font-size: 40px;
  line-height: 42px;
  margin-bottom: 40px;
}
section.polecamy-section-content .section-wrapper .section-text h2 span,
section.polecamy-section-content .section-wrapper .section-heading h2 span {
  position: relative;
  z-index: 1;
  display: inline-block;
  font-size: 27px;
}
section.polecamy-section-content .section-wrapper .section-text h2 span:before,
section.polecamy-section-content .section-wrapper .section-heading h2 span:before {
  position: absolute;
  z-index: -1;
  bottom: 5px;
  left: 2px;
  content: "";
  width: 103%;
  height: 12px;
  background-color: #e95216;
}
section.polecamy-section-content .section-wrapper .section-text p,
section.polecamy-section-content .section-wrapper .section-heading p {
  line-height: 28px;
}
section.polecamy-section-content .section-wrapper .section-text p:not(:first-of-type),
section.polecamy-section-content .section-wrapper .section-heading p:not(:first-of-type) {
  margin-top: 10px;
}
section.polecamy-section-content .section-wrapper .section-text .btn-default,
section.polecamy-section-content .section-wrapper .section-heading .btn-default {
  margin-top: 30px;
}
section.polecamy-section-content .section-wrapper .section-text ul,
section.polecamy-section-content .section-wrapper .section-heading ul {
  list-style: none;
  padding: 0 0 10px 10px;
}
section.polecamy-section-content .section-wrapper .section-text ul li,
section.polecamy-section-content .section-wrapper .section-heading ul li {
  position: relative;
  font-size: 17px;
  padding: 10px 0 10px 15px;
}
section.polecamy-section-content .section-wrapper .section-text ul li:before,
section.polecamy-section-content .section-wrapper .section-heading ul li:before {
  position: absolute;
  content: "";
  top: 16px;
  left: -10px;
  width: 12px;
  height: 12px;
  background-color: #e95216;
}
section.polecamy-section-content .section-wrapper .section-heading {
  width: 40%;
}
section.polecamy-section-content .section-wrapper .section-img {
  position: relative;
  width: 40%;
  padding-top: 40%;
  display: flex;
  justify-content: flex-end;
}
section.polecamy-section-content .section-wrapper .section-img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 90%;
  height: 90%;
  object-fit: contain;
  transform: rotate(10deg);
}
section.kontakt-section-content {
  padding-bottom: 70px;
}
section.kontakt-section-content .section-wrapper {
  padding-top: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
section.kontakt-section-content .section-wrapper .section-heading {
  width: 30%;
}
section.kontakt-section-content .section-wrapper .section-heading h3 {
  margin: 20px 0 0 0;
  font-size: 22px;
}
section.kontakt-section-content .section-wrapper .section-heading span {
  margin-top: 10px;
  display: block;
}
section.kontakt-section-content .section-wrapper .section-heading address {
  margin: 15px 0 20px 0;
}
section.kontakt-section-content .section-wrapper .section-heading a {
  color: #000;
  display: block;
  margin: 5px 0;
  transition: color 350ms;
}
section.kontakt-section-content .section-wrapper .section-heading a:hover {
  color: #3a5f89;
}
section.kontakt-section-content .section-wrapper .section-heading .social-media {
  margin-top: 25px;
  font-size: 15px;
  color: #000;
  font-weight: 500;
}
section.kontakt-section-content .section-wrapper .section-heading .social-media img {
  width: 40px;
  margin-right: 10px;
}
section.kontakt-section-content .section-wrapper .section-heading img {
  width: 200px;
}
section.kontakt-section-content .section-wrapper .section-map {
  position: relative;
  width: 60%;
  padding-top: 40%;
  background-color: #f4f4f4;
}
section.kontakt-section-content .section-wrapper .section-map iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 97%;
  height: 97%;
}
section.kontakt-section-content .section-team {
  padding-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
section.kontakt-section-content .section-team .person {
  position: relative;
  width: 23%;
  padding-top: 18%;
  margin-top: 10px;
  background-color: #f4f4f4;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.03), 0 6px 6px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
section.kontakt-section-content .section-team .person:nth-of-type(even) {
  background: #ddd;
}
section.kontakt-section-content .section-team .person .person-details {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  padding: 20px 10px;
}
section.kontakt-section-content .section-team .person .person-details a {
  display: block;
  color: #000;
  font-size: 15px;
  transition: color 350ms;
}
section.kontakt-section-content .section-team .person .person-details a:hover {
  color: #3a5f89;
}
section.kontakt-section-content .section-team .person .person-details .name {
  font-size: 18px;
  font-weight: 500;
}
section.kontakt-section-content .section-team .person .person-details p {
  font-size: 14px;
}
section.kontakt-section-content .section-team .person .person-details h3 {
  font-size: 18px;
  font-weight: 500;
}
section.kontakt-section-content .section-team .box-hidden {
  visibility: hidden;
  width: 24%;
}
section.kontakt-section-content .heading {
  padding-top: 40px;
}
section.section-footer {
  background-color: #f4f4f4;
}
section.section-footer footer .footer-flexbox {
  padding-top: 60px;
  display: flex;
  justify-content: space-between;
}
section.section-footer footer .footer-flexbox .flexbox-service:first-of-type p {
  font-size: 14px;
  margin: 10px 0;
  font-weight: 500;
}
section.section-footer footer .footer-flexbox .flexbox-service:first-of-type address {
  font-size: 14.5px;
  margin: 25px 0 10px;
}
section.section-footer footer .footer-flexbox .flexbox-service:first-of-type .contact-link {
  display: block;
  color: #000;
  font-size: 14.5px;
  font-weight: 500;
  transition: color 350ms;
}
section.section-footer footer .footer-flexbox .flexbox-service:first-of-type .contact-link:hover {
  color: #3a5f89;
}
section.section-footer footer .footer-flexbox .flexbox-service:nth-of-type(2),
section.section-footer footer .footer-flexbox .flexbox-service:nth-of-type(3) {
  margin-top: 20px;
}
section.section-footer footer .footer-flexbox .flexbox-service .service-logo img {
  height: 70px;
}
section.section-footer footer .footer-flexbox .flexbox-service h3 {
  font-size: 22px;
  margin-bottom: 15px;
}
section.section-footer footer .footer-flexbox .flexbox-service .social-media {
  display: block;
  margin-top: 25px;
}
section.section-footer footer .footer-flexbox .flexbox-service .social-media img {
  height: 35px;
}
section.section-footer footer .footer-flexbox .flexbox-service .social-media span {
  color: #000;
  font-size: 14.5px;
  margin-left: 10px;
  font-weight: 500;
}
section.section-footer footer .footer-flexbox .flexbox-service address {
  margin: 30px 0 20px 0;
  font-weight: 500;
}
section.section-footer footer .footer-flexbox .flexbox-service ul li {
  padding: 5px 0;
}
section.section-footer footer .footer-flexbox .flexbox-service ul li a {
  font-size: 14.5px;
  color: #000;
  transition: color 350ms;
}
section.section-footer footer .footer-flexbox .flexbox-service ul li a:hover {
  color: #3a5f89;
}
section.section-footer footer .alert {
  position: relative;
  background: none;
  border: none;
  border-radius: 0;
  padding: 0;
  width: 80%;
  margin: 40px auto;
}
section.section-footer footer .alert p {
  font-size: 13px;
  line-height: 22px;
  color: #000;
  font-weight: 300;
  text-align: center;
}
section.section-footer footer .alert .alert-links {
  margin-top: 10px;
  text-align: center;
}
section.section-footer footer .alert .alert-links a {
  color: #e95216;
  cursor: pointer;
  transition: all 0.3s;
  margin-right: 10px;
  font-size: 12.5px;
  font-weight: 500;
}
section.section-footer footer .alert .alert-links a:hover {
  color: #3a5f89;
}
section.section-footer footer .footer-credits-wrapper {
  margin-top: 10px;
  padding: 25px 0;
  background-color: #fff;
}
section.section-footer footer .footer-credits-wrapper .footer-credits {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}
section.section-footer footer .footer-credits-wrapper .footer-credits p {
  font-size: 13px;
  color: #444;
  font-weight: 300;
}
section.section-footer footer .footer-credits-wrapper .footer-credits p a {
  color: #e95216;
  font-weight: 600;
}
@media (max-width: 1400.98px) {
  section.section-welcome .welcome-wrapper .row-wrapper.top .welcome-element h2 {
    font-size: 26px;
    line-height: 28px;
  }
  section.section-welcome .welcome-wrapper .row-wrapper.bottom .welcome-element h2 {
    font-size: 18px;
    line-height: 24px;
  }
  section.section-about .img-absolute {
    display: none;
  }
  section.section-offer .owl-carousel-offer .item .item-text {
    padding: 0 100px 0 60px;
  }
  .section-background {
    background-position: 0 50%;
  }
  .section-process .process-description p {
    padding: 0 10%;
  }
}
@media (max-width: 1259.98px) {
  nav.section-header .header-flexbox .flexbox-logo img {
    width: 180px;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li {
    padding: 0 10px;
  }
  section.section-cta .section-wrapper .cta-content .cta-img {
    right: -20px;
  }
  section.section-welcome .welcome-wrapper {
    height: auto;
  }
  section.section-welcome .welcome-wrapper .row-wrapper {
    padding: 0;
  }
  section.section-welcome .welcome-wrapper .row-wrapper .welcome-element {
    margin: 5px 0;
  }
  section.section-welcome .welcome-wrapper .row-wrapper.top .welcome-element {
    height: 300px;
  }
  section.section-welcome .welcome-wrapper .row-wrapper.top .welcome-element:first-of-type {
    width: 100%;
  }
  section.section-welcome .welcome-wrapper .row-wrapper.top .welcome-element:first-of-type .element-image img {
    height: 100%;
    object-fit: contain;
  }
  section.section-welcome .welcome-wrapper .row-wrapper.top .welcome-element.owl-carousel-welcome-test {
    display: none;
  }
  section.section-welcome .welcome-wrapper .row-wrapper.bottom .welcome-element {
    width: 100%;
  }
  section.section-welcome .welcome-wrapper .row-wrapper.bottom .welcome-element header {
    padding: 10px 20px 10px 0;
  }
}
@media (max-width: 995.98px) {
  nav.section-header .header-flexbox .flexbox-logo img {
    width: 130px;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li {
    padding: 0 5px;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a {
    font-size: 12px;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.desktop .dropdown-toggle {
    font-size: 12px;
  }
  .offer-element .offer-item .offer-text {
    padding: 5px;
  }
  .offer-element .offer-item .offer-text h2 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .offer-element .offer-item .offer-text p {
    font-size: 13px;
  }
  .section-item-list .items-wrapper .item,
  .section-item-list .items-wrapper.item-lg .item {
    width: 47%;
    padding-top: 40%;
    margin: 10px;
  }
  section.section-offer .owl-carousel-offer .item .item-img {
    width: 40%;
    padding-top: 40%;
  }
  section.section-offer .owl-carousel-offer .item .item-text {
    width: 60%;
    padding: 0 70px 0 50px;
  }
  section.section-offer .owl-carousel-offer .item .item-text h3 {
    font-size: 38px;
    margin-bottom: 20px;
  }
  section.section-cta {
    margin: 50px 0;
  }
  section.section-cta .section-wrapper .cta-content .cta-text {
    width: 65%;
  }
  section.section-cta .section-wrapper .cta-content .cta-img {
    right: -80px;
  }
  section.realizacje-section-content .section-realizations,
  section.realizacja-section-content .section-realizations {
    justify-content: center;
  }
  section.realizacje-section-content .section-realizations .realization,
  section.realizacja-section-content .section-realizations .realization {
    width: 100%;
  }
  section.kontakt-section-content .section-wrapper {
    flex-direction: column;
  }
  section.kontakt-section-content .section-wrapper .section-heading {
    width: 100%;
    text-align: center;
  }
  section.kontakt-section-content .section-wrapper .section-map {
    width: 100%;
    padding-top: 50%;
  }
  section.kontakt-section-content .section-team .person .person-details a {
    font-size: 13px;
  }
  section.kontakt-section-content .section-team .person .person-details .name {
    font-size: 16px;
  }
  section.kontakt-section-content .section-team .person .person-details h3 {
    font-size: 17px;
  }
}
@media (max-width: 832.98px) {
  nav.section-header {
    padding: 0;
  }
  nav.section-header .header-flexbox .nav-button {
    display: flex;
    margin-right: -10px;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    background: #fff;
    z-index: 555;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close {
    display: block;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    padding: 50px;
    margin-right: 0;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav {
    flex-direction: column;
    align-items: center;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li {
    padding: 15px 0;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a {
    font-size: 16px;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.desktop {
    display: none;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.mobile {
    display: flex;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper.active {
    opacity: 1;
    visibility: visible;
  }
  .section-heading {
    margin-bottom: 20px;
  }
  .section-heading h2,
  .section-heading h3 {
    line-height: 38px;
  }
  .section-description {
    padding-top: 60px;
  }
  .section-description h2 {
    font-size: 38px;
  }
  .section-description h2 img {
    width: 120px;
  }
  .section-description p {
    padding: 0;
  }
  .section-offer-list {
    padding-top: 40px;
    flex-direction: column;
    align-items: center;
  }
  .section-offer-list h2 {
    line-height: 50px;
    font-size: 38px;
    text-align: center;
  }
  .section-offer-list .product {
    width: 80%;
  }
  .section-offer-list .product:last-of-type {
    width: 80%;
  }
  section.section-about.p-100 {
    padding: 100px 0 70px 0;
  }
  section.section-about .section-wrapper {
    flex-direction: column;
  }
  section.section-about .section-wrapper.reverse {
    flex-direction: column;
  }
  section.section-about .section-wrapper .section-text {
    order: 2;
    width: 100%;
    margin-top: 30px;
  }
  section.section-about .section-wrapper .section-img {
    width: 100%;
    padding-top: 50%;
  }
  section.section-offer .bg-wrapper {
    height: 50%;
    width: 100%;
    top: -10px;
  }
  section.section-offer .owl-carousel-offer .item {
    flex-direction: column;
    align-items: center;
  }
  section.section-offer .owl-carousel-offer .item .item-img {
    width: 70%;
    padding-top: 40%;
    margin-bottom: 20px;
  }
  section.section-offer .owl-carousel-offer .item .item-img img {
    width: 100%;
    height: 100%;
    padding: 0;
  }
  section.section-offer .owl-carousel-offer .item .item-text {
    width: 62%;
    padding: 0;
  }
  section.section-offer .owl-carousel-offer .item .item-text h3 {
    font-size: 36px;
    margin-bottom: 20px;
  }
  section.section-offer .owl-carousel-offer .item .item-text p {
    font-size: 15px;
  }
  section.section-offer .owl-carousel-offer .owl-nav {
    display: none;
  }
  section.section-opinion {
    margin: 50px 0;
  }
  section.section-opinion .owl-carousel-opinion .item {
    width: 70%;
  }
  section.section-cta {
    margin: 50px 0;
  }
  section.section-cta .section-wrapper .cta-content {
    justify-content: center;
  }
  section.section-cta .section-wrapper .cta-content .cta-text {
    width: 100%;
  }
  section.section-cta .section-wrapper .cta-content .cta-img {
    display: none;
  }
  section.o-nas-section-content h2 {
    font-size: 32px;
  }
  section.o-nas-section-content .section-wrapper {
    padding-top: 60px;
    flex-direction: column;
    align-items: center;
  }
  section.o-nas-section-content .section-wrapper .section-heading {
    width: 90%;
  }
  section.o-nas-section-content .section-wrapper .section-text {
    width: 100%;
    margin-top: 30px;
  }
  section.o-nas-section-content .section-timeline {
    margin-top: 100px;
  }
  section.o-nas-section-content .section-timeline .timeline-wrapper {
    width: 100%;
  }
  section.o-nas-section-content .section-process p {
    text-align: center;
    padding: 0;
  }
  section.offer-section-content {
    padding-bottom: 40px;
  }
  section.offer-section-content .section-products {
    flex-direction: column;
    padding-top: 40px;
  }
  section.offer-section-content .section-products.reverse {
    flex-direction: column;
  }
  section.offer-section-content .section-products .owl-carousel-products {
    width: 90%;
  }
  section.offer-section-content .section-products .owl-carousel-products .owl-stage {
    height: 300px;
  }
  section.offer-section-content .section-products .products-text {
    margin-top: 40px;
    width: 100%;
    text-align: center;
  }
  section.offer-section-content .section-products .products-image {
    width: 90%;
    padding-top: 50%;
  }
  section.offer-section-content .products-cta {
    margin-top: 40px;
  }
  section.offer-section-content .products-cta .img-absolute {
    width: 100%;
  }
  section.offer-section-content .products-cta .cta-wrapper {
    flex-direction: column;
    align-items: center;
  }
  section.offer-section-content .products-cta .cta-wrapper .cta-heading {
    width: 100%;
    text-align: center;
    margin-right: 0;
  }
  section.offer-section-content .products-cta .cta-wrapper .cta-heading h3 {
    font-size: 40px;
  }
  section.offer-section-content .products-cta .cta-wrapper .text {
    width: 90%;
    text-align: center;
  }
  section.offer-section-content .products-cta .cta-wrapper .text h2 {
    font-size: 28px;
  }
  section.offer-section-content .products-cta .cta-wrapper .text .btn-arrow {
    justify-content: center;
  }
  section.offer-section-content .section-table td {
    min-width: 200px;
  }
  section.offer-section-content .section-distributors {
    padding-top: 40px;
  }
  section.offer-section-content .section-distributors h2 {
    font-size: 38px;
  }
  section.offer-section-content .section-distributors .distributors-wrapper {
    flex-direction: column;
  }
  section.offer-section-content .section-distributors .image img {
    width: 50%;
  }
  section.offer-section-content .section-distributors .distributors-inner {
    width: 90%;
    margin-top: 20px;
  }
  section.offer-section-content .section-distributors .distributors-inner ul li {
    font-size: 14px;
  }
  section.offer-section-content .section-distributors .distributors-inner img {
    display: none;
  }
  section.section-footer footer .footer-flexbox .flexbox-service {
    padding-right: 20px;
  }
  section.section-footer footer .footer-flexbox .flexbox-service h3 {
    font-size: 18px;
  }
}
@media (max-width: 760.98px) {
  .offer-element .offer-item {
    text-align: center;
  }
  .offer-element .offer-item h2 {
    margin-bottom: 0;
    font-size: 15px;
  }
  .offer-element .offer-item p {
    display: none;
  }
  .main-section-heading {
    padding: 120px 0 40px 0;
    width: 100%;
    margin: 0;
    text-align: center;
  }
  .main-section-heading h1 {
    font-size: 40px;
  }
  .main-section-heading p {
    font-size: 16px;
  }
  .section-heading {
    text-align: center;
  }
  .process-cta {
    margin-top: 60px;
  }
  .section-process {
    padding-top: 60px;
  }
  .section-process .process-description {
    padding-top: 0;
  }
  .section-description h2 {
    font-size: 32px;
  }
  .section-features {
    margin-top: 40px;
  }
  .section-item-list {
    padding-top: 80px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .section-item-list .items-wrapper,
  .section-item-list .items-wrapper.items-lg {
    justify-content: center;
  }
  .section-item-list .items-wrapper .item,
  .section-item-list .items-wrapper.items-lg .item {
    width: 80%;
    padding-top: 60%;
  }
  .section-item-list.leasing .leasing-wrapper {
    flex-direction: column;
    align-items: center;
  }
  .section-item-list.leasing .leasing-wrapper .navigation {
    margin-top: 0;
    padding-bottom: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 0;
    border-bottom: 1px solid #e95216;
  }
  .section-item-list.leasing .leasing-wrapper .navigation p {
    font-size: 18px;
  }
  .section-item-list.leasing .leasing-wrapper .navigation ul {
    text-align: center;
  }
  .section-item-list.leasing .leasing-wrapper .leasing-content {
    width: 90%;
  }
  .section-item-list.leasing .leasing-wrapper .leasing-description {
    padding-left: 0;
  }
  .section-item-list.leasing .leasing-wrapper .leasing-description ul {
    flex-direction: column;
    border: none;
    padding: 10px 0 0 20px;
  }
  section.inwestycje-section-content {
    padding-bottom: 80px;
  }
  section.inwestycje-section-content .section-wrapper {
    padding-top: 40px;
    flex-direction: column;
  }
  section.inwestycje-section-content .section-wrapper .section-text {
    width: 100%;
    text-align: center;
  }
  section.inwestycje-section-content .section-wrapper .section-img {
    margin-top: 30px;
    width: 90%;
    padding-top: 50%;
  }
  section.inwestycje-section-content .section-wrapper .section-img img {
    object-position: 20% 55%;
  }
  section.inwestycje-section-content .section-cta .cta-wrapper h3 {
    font-size: 28px;
  }
  section.inwestycje-section-content .section-cta .cta-wrapper .btn-default {
    margin-top: 20px;
  }
  section.polecamy-section-content .section-wrapper {
    padding-top: 60px;
    flex-direction: column;
  }
  section.polecamy-section-content .section-wrapper.reverse {
    flex-direction: column;
  }
  section.polecamy-section-content .section-wrapper.reverse .section-img {
    margin-top: 60px;
  }
  section.polecamy-section-content .section-wrapper.reverse .section-text {
    padding-right: 0;
  }
  section.polecamy-section-content .section-wrapper .section-text,
  section.polecamy-section-content .section-wrapper .section-heading {
    width: 100%;
    padding-right: 0;
    text-align: center;
  }
  section.polecamy-section-content .section-wrapper .section-text ul,
  section.polecamy-section-content .section-wrapper .section-heading ul {
    text-align: left;
  }
  section.polecamy-section-content .section-wrapper .section-heading {
    width: 100%;
  }
  section.polecamy-section-content .section-wrapper .section-img {
    margin-top: 30px;
    width: 70%;
    padding-top: 60%;
  }
  section.polecamy-section-content .section-gallery {
    padding-top: 60px;
  }
  section.faq-section-content .accordion {
    padding-top: 40px;
  }
  section.faq-section-content .accordion .accordion-item .item-description {
    padding: 20px 0 20px 40px;
  }
  section.kontakt-section-content .heading {
    text-align: center;
  }
  section.kontakt-section-content .section-wrapper {
    padding-top: 40px;
  }
  section.kontakt-section-content .section-team {
    justify-content: center;
  }
  section.kontakt-section-content .section-team .person {
    width: 80%;
    padding-top: 60%;
    margin: 10px;
  }
  section.kontakt-section-content .section-team .person .person-details a {
    font-size: 15px;
  }
  section.kontakt-section-content .section-team .person .person-details .name {
    font-size: 18px;
  }
  section.kontakt-section-content .section-team .person .person-details h3 {
    font-size: 18px;
  }
  section.section-footer footer .footer-flexbox {
    flex-direction: column;
    align-items: center;
  }
  section.section-footer footer .footer-flexbox .flexbox-service {
    width: 100%;
    text-align: center;
    padding-right: 0;
  }
  section.section-footer footer .footer-flexbox .flexbox-service h3 {
    font-size: 18px;
  }
  section.section-footer footer .alert {
    width: 100%;
    margin: 20px 0;
  }
  section.section-footer footer .footer-credits-wrapper .footer-credits {
    justify-content: center;
    text-align: center;
  }
  section.section-footer footer .footer-credits-wrapper .footer-credits p {
    font-size: 11px;
  }
}
@media (max-width: 575.98px) {
  .fixed-button {
    left: -340px;
    bottom: 20px;
  }
  .fixed-button .button-wrapper .service-box {
    width: 340px;
  }
  .fixed-button .button-wrapper .service-box h3 {
    margin-bottom: 10px;
    font-size: 18px;
  }
  .offer-element {
    flex-direction: column;
    align-items: center;
  }
  .offer-element .offer-item {
    width: 70%;
    margin: 3px;
  }
  .offer-element .offer-item .offer-text h2 {
    font-size: 16px;
  }
  .section-item-list {
    padding-top: 40px;
  }
  .section-background {
    background: none;
    background-color: #f4f4f4;
  }
  .section-heading {
    margin-bottom: 20px;
  }
  .section-heading h2,
  .section-heading h3 {
    font-size: 32px !important;
  }
  .section-heading h2 span,
  .section-heading h3 span {
    font-size: 22px;
  }
  .section-features {
    margin-top: 40px;
    padding: 40px 0;
  }
  .section-features .features-wrapper {
    flex-direction: column;
    align-items: center;
  }
  .section-features .features-wrapper .feature {
    width: 80%;
  }
  .section-features .features-wrapper .feature:not(:first-of-type) {
    margin-top: 20px;
  }
  .section-gallery .gallery-wrapper a {
    width: 49%;
    padding-top: 48%;
  }
  .section-process .process-flexbox {
    padding-top: 30px;
    flex-direction: column;
    align-items: center;
  }
  .section-process .process-flexbox .process-stage {
    width: 80%;
  }
  .section-process .process-flexbox .process-stage:not(:first-of-type) {
    margin-top: 20px;
  }
  .section-process .process-description p {
    padding: 0;
  }
  section.section-welcome .welcome-wrapper {
    padding: 80px 0 0 0;
  }
  section.section-welcome .welcome-wrapper .row-wrapper.top .welcome-element.img-background {
    display: none;
  }
  section.section-welcome .welcome-wrapper .row-wrapper.top .welcome-element h2 {
    font-size: 18px;
  }
  section.section-welcome .welcome-wrapper .row-wrapper.top .welcome-element p {
    font-size: 14px;
  }
  section.section-welcome .welcome-wrapper .row-wrapper.top .welcome-element .element-image {
    width: 40%;
  }
  section.section-welcome .welcome-wrapper .row-wrapper.top .welcome-element header {
    width: 60%;
  }
  section.section-welcome .welcome-wrapper .row-wrapper.bottom .welcome-element .element-image {
    display: none;
  }
  section.section-welcome .welcome-wrapper .row-wrapper.bottom .welcome-element h2 {
    font-size: 16px;
  }
  section.section-welcome .welcome-wrapper .row-wrapper.bottom .welcome-element p {
    font-size: 13px;
  }
  section.section-welcome .welcome-wrapper .row-wrapper.bottom .welcome-element header {
    width: 100%;
  }
  section.section-about.p-100 {
    padding: 40px 0 70px 0;
  }
  section.section-about .section-wrapper .section-text {
    text-align: center;
  }
  section.section-about .section-wrapper .section-text h3 {
    font-size: 32px;
  }
  section.section-about .section-wrapper .section-text h3 span {
    font-size: 28px;
  }
  section.section-about .section-wrapper .section-img {
    width: 90%;
  }
  section.section-offer {
    margin-top: 0;
  }
  section.section-offer .bg-wrapper {
    height: 44%;
  }
  section.section-offer .mobile {
    display: block;
  }
  section.section-offer .owl-carousel-offer .item .item-text {
    text-align: center;
    align-items: center;
    width: 95%;
  }
  section.section-offer .owl-carousel-offer .item .item-text h3 {
    width: 100%;
    font-size: 24px;
    margin: 15px 0;
  }
  section.section-offer .owl-carousel-offer .item .item-text .btn-default {
    margin-top: 10px;
  }
  section.offer-section-content .section-distributors h2 {
    font-size: 32px;
  }
  section.offer-section-content .section-cooperation {
    padding: 40px 0;
    margin-top: 40px;
  }
  section.section-opinion {
    margin: 50px 0;
  }
  section.section-opinion .section-parallax {
    height: 350px;
  }
  section.section-opinion .owl-carousel-opinion .item {
    width: 80%;
  }
  section.section-opinion .owl-carousel-opinion .item h4 {
    font-size: 22px;
  }
  section.section-opinion .owl-carousel-opinion .item span {
    margin-top: 10px;
    font-size: 17px;
  }
  section.section-opinion .owl-carousel-opinion .owl-nav {
    bottom: 55px;
  }
  section.section-cta {
    margin: 0;
    padding: 40px 0 80px 0;
  }
  section.section-cta .section-wrapper .img-absolute {
    width: 100%;
  }
  section.section-cta .section-wrapper .cta-content .cta-text {
    padding: 20px;
  }
  section.section-cta .section-wrapper .cta-content .cta-text h2 {
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 10px;
  }
  section.section-cta .section-wrapper .cta-content .cta-text h2 strong {
    font-size: 28px;
  }
  section.section-cta .section-wrapper .cta-content .cta-text .btn-arrow {
    margin-top: 0;
  }
  section.o-nas-section-content .section-wrapper .section-heading {
    width: 100%;
  }
  section.o-nas-section-content .section-wrapper .section-text {
    width: 100%;
    text-align: center;
  }
  section.o-nas-section-content .section-timeline {
    margin-top: 40px;
  }
  section.o-nas-section-content .section-timeline:before {
    display: none;
  }
  section.o-nas-section-content .section-timeline .timeline-wrapper .timeline {
    width: 90%;
    padding: 30px 20px;
  }
  .process-cta {
    padding: 40px 0;
  }
  .process-cta .cta-wrapper h3 {
    font-size: 26px;
    text-align: center;
  }
  section.polecamy-section-content .section-wrapper .section-text h2,
  section.polecamy-section-content .section-wrapper .section-heading h2 {
    font-size: 32px;
    line-height: 38px;
  }
  section.polecamy-section-content .section-wrapper .section-text h2 span,
  section.polecamy-section-content .section-wrapper .section-heading h2 span {
    font-size: 22px;
  }
  section.polecamy-section-content .section-features h2 {
    font-size: 32px;
  }
  section.polecamy-section-content .section-gallery {
    padding-top: 60px;
  }
  section.realizacje-section-content .section-realizations,
  section.realizacja-section-content .section-realizations {
    justify-content: center;
  }
  section.realizacje-section-content .section-realizations .realization,
  section.realizacja-section-content .section-realizations .realization {
    flex-direction: column;
    margin-top: 30px;
  }
  section.realizacje-section-content .section-realizations .realization .image,
  section.realizacja-section-content .section-realizations .realization .image {
    width: 100%;
    padding-top: 50%;
  }
  section.realizacje-section-content .section-realizations .realization .description,
  section.realizacja-section-content .section-realizations .realization .description {
    width: 100%;
    margin: 20px 0 0 0;
  }
}
