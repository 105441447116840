// Large devices (desktops, less than 1200px)
@media (max-width: 1400.98px) {
  section.section-welcome {
    .welcome-wrapper {
      .row-wrapper {
        &.top {
          .welcome-element {
            h2 {
              font-size: 26px;
              line-height: 28px;
            }
          }
        }

        &.bottom {
          .welcome-element {
            h2 {
              font-size: 18px;
              line-height: 24px;
            }
          }
        }
      }
    }
  }

  section.section-about {
    .img-absolute {
      display: none;
    }
  }

  section.section-offer {
    .owl-carousel-offer {
      .item {
        .item-text {
          padding: 0 100px 0 60px;
        }
      }
    }
  }

  .section-background {
    background-position: 0 50%;
  }

  .section-process {
    .process-description {
      p {
        padding: 0 10%;
      }
    }
  }
}

// Large devices (desktops, less than 1258px)
@media (max-width: 1259.98px) {
  nav.section-header {
    .header-flexbox {
      .flexbox-logo {
        img {
          width: 180px;
        }
      }

      .flexbox-nav-wrapper {
        .flexbox-nav-inner {
          .flexbox-nav {
            li {
              padding: 0 10px;
            }
          }
        }
      }
    }
  }

  section.section-cta {
    .section-wrapper {
      .cta-content {
        .cta-img {
          right: -20px;
        }
      }
    }
  }

  section.section-welcome {
    .welcome-wrapper {
      height: auto;

      .row-wrapper {
        padding: 0;

        .welcome-element {
          margin: 5px 0;
        }

        &.top {
          .welcome-element {
            height: 300px;

            &:first-of-type {
              width: 100%;

              .element-image {
                img {
                  height: 100%;
                  object-fit: contain;
                }
              }
            }

            &.owl-carousel-welcome-test {
              display: none;
            }
          }
        }

        &.bottom {
          .welcome-element {
            width: 100%;

            header {
              padding: 10px 20px 10px 0;
            }
          }
        }
      }
    }
  }
}

// Medium devices (tablets, less than 994px)
@media (max-width: 995.98px) {
  nav.section-header {
    .header-flexbox {
      .flexbox-logo {
        img {
          width: 130px;
        }
      }

      .flexbox-nav-wrapper {
        .flexbox-nav-inner {
          .flexbox-nav {
            li {
              padding: 0 5px;

              a {
                font-size: 12px;
              }

              &.desktop {
                .dropdown-toggle {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }

  .offer-element {
    .offer-item {
      .offer-text {
        padding: 5px;

        h2 {
          font-size: 18px;
          margin-bottom: 10px;
        }

        p {
          font-size: 13px;
        }
      }
    }
  }

  .section-item-list {
    .items-wrapper,
    .items-wrapper.item-lg {
      .item {
        width: 47%;
        padding-top: 40%;
        margin: 10px;
      }
    }
  }

  section.section-offer {
    .owl-carousel-offer {
      .item {
        .item-img {
          width: 40%;
          padding-top: 40%;
        }

        .item-text {
          width: 60%;
          padding: 0 70px 0 50px;

          h3 {
            font-size: 38px;
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  section.section-cta {
    margin: 50px 0;

    .section-wrapper {
      .cta-content {
        .cta-text {
          width: 65%;
        }

        .cta-img {
          right: -80px;
        }
      }
    }
  }

  section.realizacje-section-content,
  section.realizacja-section-content {
    .section-realizations {
      justify-content: center;

      .realization {
        width: 100%;
      }
    }
  }

  section.kontakt-section-content {
    .section-wrapper {
      flex-direction: column;

      .section-heading {
        width: 100%;
        text-align: center;
      }

      .section-map {
        width: 100%;
        padding-top: 50%;
      }
    }

    .section-team {
      .person {
        .person-details {
          a {
            font-size: 13px;
          }

          .name {
            font-size: 16px;
          }

          h3 {
            font-size: 17px;
          }
        }
      }
    }
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 832.98px) {
  nav.section-header {
    padding: 0;

    .header-flexbox {
      .nav-button {
        display: flex;
        margin-right: -10px;
      }

      .flexbox-nav-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        overflow-y: scroll;
        background: #fff;
        z-index: 555;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s;

        .nav-button-close {
          display: block;
        }

        .flexbox-nav-inner {
          display: flex;
          flex-direction: column;
          justify-content: center;
          min-height: 100%;
          padding: 50px;
          margin-right: 0;

          .flexbox-nav {
            flex-direction: column;
            align-items: center;

            li {
              padding: 15px 0;

              a {
                font-size: 16px;
              }

              &.desktop {
                display: none;
              }

              &.mobile {
                display: flex;
              }
            }
          }
        }

        &.active {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

  .section-heading {
    margin-bottom: 20px;

    h2,
    h3 {
      line-height: 38px;
    }
  }

  .section-description {
    padding-top: 60px;

    h2 {
      font-size: 38px;

      img {
        width: 120px;
      }
    }

    p {
      padding: 0;
    }
  }

  .section-offer-list {
    padding-top: 40px;
    flex-direction: column;
    align-items: center;

    h2 {
      line-height: 50px;
      font-size: 38px;
      text-align: center;
    }

    .product {
      width: 80%;

      &:last-of-type {
        width: 80%;
      }
    }
  }

  section.section-about {
    &.p-100 {
      padding: 100px 0 70px 0;
    }

    .section-wrapper {
      flex-direction: column;

      &.reverse {
        flex-direction: column;
      }

      .section-text {
        order: 2;
        width: 100%;
        margin-top: 30px;
      }

      .section-img {
        width: 100%;
        padding-top: 50%;
      }
    }
  }

  section.section-offer {
    .bg-wrapper {
      height: 50%;
      width: 100%;
      top: -10px;
    }

    .owl-carousel-offer {
      .item {
        flex-direction: column;
        align-items: center;

        .item-img {
          width: 70%;
          padding-top: 40%;
          margin-bottom: 20px;

          img {
            width: 100%;
            height: 100%;
            padding: 0;
          }
        }

        .item-text {
          width: 62%;
          padding: 0;

          h3 {
            font-size: 36px;
            margin-bottom: 20px;
          }

          p {
            font-size: 15px;
          }
        }
      }

      .owl-nav {
        display: none;
      }
    }
  }

  section.section-opinion {
    margin: 50px 0;

    .owl-carousel-opinion {
      .item {
        width: 70%;
      }
    }
  }

  section.section-cta {
    margin: 50px 0;

    .section-wrapper {
      .cta-content {
        justify-content: center;

        .cta-text {
          width: 100%;
        }

        .cta-img {
          display: none;
        }
      }
    }
  }

  section.o-nas-section-content {
    h2 {
      font-size: 32px;
    }

    .section-wrapper {
      padding-top: 60px;
      flex-direction: column;
      align-items: center;

      .section-heading {
        width: 90%;
      }

      .section-text {
        width: 100%;
        margin-top: 30px;
      }
    }

    .section-timeline {
      margin-top: 100px;

      .timeline-wrapper {
        width: 100%;
      }
    }

    .section-process {
      p {
        text-align: center;
        padding: 0;
      }
    }
  }

  section.offer-section-content {
    padding-bottom: 40px;

    .section-products {
      flex-direction: column;
      padding-top: 40px;

      &.reverse {
        flex-direction: column;
      }

      .owl-carousel-products {
        width: 90%;

        .owl-stage {
          height: 300px;
        }
      }

      .products-text {
        margin-top: 40px;
        width: 100%;
        text-align: center;
      }

      .products-image {
        width: 90%;
        padding-top: 50%;
      }
    }

    .products-cta {
      margin-top: 40px;

      .img-absolute {
        width: 100%;
      }

      .cta-wrapper {
        flex-direction: column;
        align-items: center;

        .cta-heading {
          width: 100%;
          text-align: center;
          margin-right: 0;

          h3 {
            font-size: 40px;
          }
        }

        .text {
          width: 90%;
          text-align: center;

          h2 {
            font-size: 28px;
          }

          .btn-arrow {
            justify-content: center;
          }
        }
      }
    }

    .section-table {
      td {
        min-width: 200px;
      }
    }

    .section-distributors {
      padding-top: 40px;

      h2 {
        font-size: 38px;
      }

      .distributors-wrapper {
        flex-direction: column;
      }

      .image {
        img {
          width: 50%;
        }
      }

      .distributors-inner {
        width: 90%;
        margin-top: 20px;

        ul {
          li {
            font-size: 14px;
          }
        }

        img {
          display: none;
        }
      }
    }
  }

  section.section-footer {
    footer {
      .footer-flexbox {
        .flexbox-service {
          padding-right: 20px;

          h3 {
            font-size: 18px;
          }
        }
      }
    }
  }
}

// Extra small devices (portrait phones, less than 759px)
@media (max-width: 760.98px) {
  .offer-element {
    .offer-item {
      text-align: center;

      h2 {
        margin-bottom: 0;
        font-size: 15px;
      }

      p {
        display: none;
      }
    }
  }

  .main-section-heading {
    padding: 120px 0 40px 0;
    width: 100%;
    margin: 0;
    text-align: center;

    h1 {
      font-size: 40px;
    }

    p {
      font-size: 16px;
    }
  }

  .section-heading {
    text-align: center;
  }

  .process-cta {
    margin-top: 60px;
  }

  .section-process {
    padding-top: 60px;

    .process-description {
      padding-top: 0;
    }
  }

  .section-description {
    h2 {
      font-size: 32px;
    }
  }

  .section-features {
    margin-top: 40px;
  }

  .section-item-list {
    padding-top: 80px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .items-wrapper,
    .items-wrapper.items-lg {
      justify-content: center;

      .item {
        width: 80%;
        padding-top: 60%;
      }
    }

    &.leasing {
      .leasing-wrapper {
        flex-direction: column;
        align-items: center;

        .navigation {
          margin-top: 0;
          padding-bottom: 10px;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          border-right: 0;
          border-bottom: 1px solid @color1;

          p {
            font-size: 18px;
          }

          ul {
            text-align: center;
          }
        }

        .leasing-content {
          width: 90%;
        }

        .leasing-description {
          padding-left: 0;

          ul {
            flex-direction: column;
            border: none;
            padding: 10px 0 0 20px;
          }
        }
      }
    }
  }

  section.inwestycje-section-content {
    padding-bottom: 80px;

    .section-wrapper {
      padding-top: 40px;
      flex-direction: column;

      .section-text {
        width: 100%;
        text-align: center;
      }

      .section-img {
        margin-top: 30px;
        width: 90%;
        padding-top: 50%;

        img {
          object-position: 20% 55%;
        }
      }
    }

    .section-cta {
      .cta-wrapper {
        h3 {
          font-size: 28px;
        }

        .btn-default {
          margin-top: 20px;
        }
      }
    }
  }

  section.polecamy-section-content {
    .section-wrapper {
      padding-top: 60px;
      flex-direction: column;

      &.reverse {
        flex-direction: column;

        .section-img {
          margin-top: 60px;
        }

        .section-text {
          padding-right: 0;
        }
      }

      .section-text,
      .section-heading {
        width: 100%;
        padding-right: 0;
        text-align: center;

        ul {
          text-align: left;
        }
      }

      .section-heading {
        width: 100%;
      }

      .section-img {
        margin-top: 30px;
        width: 70%;
        padding-top: 60%;
      }
    }

    .section-gallery {
      padding-top: 60px;
    }
  }

  section.faq-section-content {
    .accordion {
      padding-top: 40px;

      .accordion-item {
        .item-description {
          padding: 20px 0 20px 40px;
        }
      }
    }
  }

  section.kontakt-section-content {
    .heading {
      text-align: center;
    }

    .section-wrapper {
      padding-top: 40px;
    }

    .section-team {
      justify-content: center;

      .person {
        width: 80%;
        padding-top: 60%;
        margin: 10px;

        .person-details {
          a {
            font-size: 15px;
          }

          .name {
            font-size: 18px;
          }

          h3 {
            font-size: 18px;
          }
        }
      }
    }
  }

  section.section-footer {
    footer {
      .footer-flexbox {
        flex-direction: column;
        align-items: center;

        .flexbox-service {
          width: 100%;
          text-align: center;
          padding-right: 0;

          h3 {
            font-size: 18px;
          }
        }
      }

      .alert {
        width: 100%;
        margin: 20px 0;
      }

      .footer-credits-wrapper {
        .footer-credits {
          justify-content: center;
          text-align: center;

          p {
            font-size: 11px;
          }
        }
      }
    }
  }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .fixed-button {
    left: -340px;
    bottom: 20px;

    .button-wrapper {
      .service-box {
        width: 340px;

        h3 {
          margin-bottom: 10px;
          font-size: 18px;
        }
      }
    }
  }

  .offer-element {
    flex-direction: column;
    align-items: center;

    .offer-item {
      width: 70%;
      margin: 3px;

      .offer-text {
        h2 {
          font-size: 16px;
        }
      }
    }
  }

  .section-item-list {
    padding-top: 40px;
  }

  .section-background {
    background: none;
    background-color: #f4f4f4;
  }

  .section-heading {
    margin-bottom: 20px;

    h2,
    h3 {
      font-size: 32px !important;

      span {
        font-size: 22px;
      }
    }
  }

  .section-features {
    margin-top: 40px;
    padding: 40px 0;

    .features-wrapper {
      flex-direction: column;
      align-items: center;

      .feature {
        width: 80%;

        &:not(:first-of-type) {
          margin-top: 20px;
        }
      }
    }
  }

  .section-gallery {
    .gallery-wrapper {
      a {
        width: 49%;
        padding-top: 48%;
      }
    }
  }

  .section-process {
    .process-flexbox {
      padding-top: 30px;
      flex-direction: column;
      align-items: center;

      .process-stage {
        width: 80%;

        &:not(:first-of-type) {
          margin-top: 20px;
        }
      }
    }

    .process-description {
      p {
        padding: 0;
      }
    }
  }

  section.section-welcome {
    .welcome-wrapper {
      padding: 80px 0 0 0;

      .row-wrapper {
        &.top {
          .welcome-element {
            &.img-background {
              display: none;
            }

            h2 {
              font-size: 18px;
            }

            p {
              font-size: 14px;
            }

            .element-image {
              width: 40%;
            }

            header {
              width: 60%;
            }
          }
        }

        &.bottom {
          .welcome-element {
            .element-image {
              display: none;
            }

            h2 {
              font-size: 16px;
            }

            p {
              font-size: 13px;
            }

            header {
              width: 100%;
            }
          }
        }
      }
    }
  }

  section.section-about {
    &.p-100 {
      padding: 40px 0 70px 0;
    }

    .section-wrapper {
      .section-text {
        text-align: center;

        h3 {
          font-size: 32px;

          span {
            font-size: 28px;
          }
        }
      }

      .section-img {
        width: 90%;
      }
    }
  }

  section.section-offer {
    margin-top: 0;

    .bg-wrapper {
      height: 44%;
    }

    .mobile {
      display: block;
    }

    .owl-carousel-offer {
      .item {
        .item-text {
          text-align: center;
          align-items: center;
          width: 95%;

          h3 {
            width: 100%;
            font-size: 24px;
            margin: 15px 0;
          }

          .btn-default {
            margin-top: 10px;
          }
        }
      }
    }
  }

  section.offer-section-content {
    .section-distributors {
      h2 {
        font-size: 32px;
      }
    }

    .section-cooperation {
      padding: 40px 0;
      margin-top: 40px;
    }
  }

  section.section-opinion {
    margin: 50px 0;

    .section-parallax {
      height: 350px;
    }

    .owl-carousel-opinion {
      .item {
        width: 80%;

        h4 {
          font-size: 22px;
        }

        span {
          margin-top: 10px;
          font-size: 17px;
        }
      }

      .owl-nav {
        bottom: 55px;
      }
    }
  }

  section.section-cta {
    margin: 0;
    padding: 40px 0 80px 0;

    .section-wrapper {
      .img-absolute {
        width: 100%;
      }

      .cta-content {
        .cta-text {
          padding: 20px;

          h2 {
            font-size: 20px;
            line-height: 32px;
            margin-bottom: 10px;

            strong {
              font-size: 28px;
            }
          }

          .btn-arrow {
            margin-top: 0;
          }
        }
      }
    }
  }

  section.o-nas-section-content {
    .section-wrapper {
      .section-heading {
        width: 100%;
      }

      .section-text {
        width: 100%;
        text-align: center;
      }
    }

    .section-timeline {
      margin-top: 40px;

      &:before {
        display: none;
      }

      .timeline-wrapper {
        .timeline {
          width: 90%;
          padding: 30px 20px;
        }
      }
    }
  }

  .process-cta {
    padding: 40px 0;

    .cta-wrapper {
      h3 {
        font-size: 26px;
        text-align: center;
      }
    }
  }

  section.polecamy-section-content {
    .section-wrapper {
      .section-text,
      .section-heading {
        h2 {
          font-size: 32px;
          line-height: 38px;

          span {
            font-size: 22px;
          }
        }
      }
    }

    .section-features {
      h2 {
        font-size: 32px;
      }
    }

    .section-gallery {
      padding-top: 60px;
    }
  }

  section.realizacje-section-content,
  section.realizacja-section-content {
    .section-realizations {
      justify-content: center;

      .realization {
        flex-direction: column;
        margin-top: 30px;

        .image {
          width: 100%;
          padding-top: 50%;
        }

        .description {
          width: 100%;
          margin: 20px 0 0 0;
        }
      }
    }
  }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 400px) {
}
